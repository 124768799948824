import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual, xorWith } from 'lodash';

import ModalWindow from '../../ModalWindow';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import { LongCheckboxArr, Preloader } from '../../../components';

import { filteredInstallersSelector } from '../selectors';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { getAllInstallers, getAllConnectedInstallers, setAllInstallers } from '../slice';
import { getDataListCompany_sm_oemSelector } from '../../../redux-store/selectors/dataList';
import { getModalsAddPvInstallerToOEMSelector } from '../../../redux-store/selectors/modals';
import { addPvInstallerToOEM, GET_INSTALLERS_PARAMS, addPvInstallerToOEMForm, getConnectedInstallersRequestData } from '../constants';

import i18n from '../../../i18n';

import '../index.scss';

/**
 * Renders add PV installers to ORM modal window for PvInstallers page
 * @memberof module:PvInstallers
 */
const AddPVInstallerToOEMWindow = (props) => {
  const { handleOnClose, companyOemId, supportOemId, handleSubmit, modalID } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(({ pvInstallersDetails }) => pvInstallersDetails.isLoading);

  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const modal = useSelector(getModalsAddPvInstallerToOEMSelector);
  const filteredInstallers = useSelector(filteredInstallersSelector);
  const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
  const customEqual = (oldValue, newValue) => isArrayEqual(oldValue, newValue);
  const listOfOEMsFromTable = useSelector(getDataListCompany_sm_oemSelector, customEqual);

  const [checkedFields, setCheckedFields] = useState([]);

  const {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS,
    URL
  } = useMemo(() => getConnectedInstallersRequestData({ myRoleType, supportOemId, companyOemId }), [companyOemId, myRoleType, supportOemId]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = checkedFields.reduce((acc, point) => (point.isChecked ? [...acc, point._id] : acc), []);
    handleSubmit(dataForReq);
  };

  const handleChange = useCallback((value) => {
    setCheckedFields((prev) => {
      const copy = [...prev];
      const indexOfPoint = copy.findIndex((point) => point._id === value);

      copy[indexOfPoint] = {
        ...copy[indexOfPoint],
        isChecked: !copy[indexOfPoint].isChecked
      };
      return copy;
    });
  }, []);

  useEffect(() => {
    if (listOfOEMsFromTable) {
      dispatch(getAllInstallers({ url: URL, params: GET_INSTALLERS_PARAMS }));
      dispatch(getAllConnectedInstallers({ url: GET_CONNECTED_INSTALLERS_URL, params: GET_CONNECTED_INSTALLERS_PARAMS }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfOEMsFromTable]);

  useEffect(() => {
    if (!modal.opened && checkedFields.length) {
      setCheckedFields([]);
    }

    if (filteredInstallers.length) {
      setCheckedFields(filteredInstallers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInstallers, modal.opened]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setAllInstallers({ installers: [] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedInstallers = checkedFields.filter((point) => point.isChecked === true).length;
  return (
    <ModalWindow
      customClass="max-width-550"
      modalID={modalID}
    >
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addInstaller')}</h5>
      </div>
      {isLoading ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body modal-add-installer-to-oem">
            <form
              id={addPvInstallerToOEM}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <LongCheckboxArr
                formId={addPvInstallerToOEMForm}
                handleChange={handleChange}
                noDataText="NoPVinstaller"
                checkedData={checkedFields}
                isShowSelectAllCheckbox={false}
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              <span>
                {i18n.t('Selected')}
              </span>
              <span>
                {': '}
                {selectedInstallers}
              </span>
            </p>
            <div style={{ display: 'flex' }}>
              <CancelButton onClickHandler={handleOnClose} />
              <PrimaryButton
                customButtonClass="ml-2 installer-to-oem-btn"
                form={addPvInstallerToOEM}
                disabled={!selectedInstallers}
                i18nLabel="add"
                isSubmit
                type="plus"
              />
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddPVInstallerToOEMWindow.propTypes = {
  companyOemId: PropTypes.string.isRequired,
  supportOemId: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired
};

const form = reduxForm({
  form: addPvInstallerToOEMForm
})(AddPVInstallerToOEMWindow);

export default memo(form);
