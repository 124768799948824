import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderUserNameMonitoring,
  RenderDate,
  RenderWorkStatus,
  RenderPriorityStatus,
  RenderSignalIcon,
  RenderAssignedStatus,
  RenderPlant,
  RenderLastLogin,
  RenderMonitoringTableButtons,
  RenderGateway
} from '../../components/ReactTable/renderFunctions';
import { EditColumns } from '../../components';
import i18n from '../../i18n';
import { getExpandedIcon, getCheckbox } from '../../components/ReactTableV2';
import './index.scss';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { columns: additionalColumns, width, queries }) => ([
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  {
    id: 'select-col',
    enableResizing: false,
    header: ({ table }) => getCheckbox({
      checked: table.getIsAllRowsSelected(),
      indeterminate: table.getIsSomeRowsSelected(),
      onChange: table.getToggleAllRowsSelectedHandler()
    }),

    cell: ({ row }) => getCheckbox({
      checked: row.getIsSelected(),
      disabled: !row.getCanSelect(),
      indeterminate: row.getIsSomeSelected(),
      onChange: row.getToggleSelectedHandler()
    }),
    meta: {
      thClasses: 'select-col p-0',
      tdClasses: 'select-col p-0'
    }
  },
  columnHelper.accessor('gateway', {
    id: 'signal',
    header: i18n.t('signal'),
    cell: (d) => RenderSignalIcon({ original: { gateway: { signal: d.getValue()?.signal } } }),
    size: width.signal
  }),

  additionalColumns.sm_id ? columnHelper.accessor('gateway', {
    id: 'sm_id',
    header: i18n.t('SMID'),
    cell: (d) => RenderGateway({ value: d.getValue()?.sm_id }),
    size: width.sm_id
  }) : null,

  additionalColumns.plant ? columnHelper.accessor('plant', {
    id: 'plant',
    header: i18n.t('plantLabel'),
    cell: (d) => RenderPlant(d.row),
    size: width.plant
  }) : null,
  columnHelper.accessor('name', {
    id: 'name',
    header: i18n.t('eUser'),
    cell: (d) => RenderUserNameMonitoring(d.row),
    size: width.name
  }),
  additionalColumns.note ? columnHelper.accessor('user', {
    id: 'note',
    header: i18n.t('userSettingNotesLabel'),
    cell: (d) => d.getValue()?.note,
    size: width.note

  }) : null,

  additionalColumns.city ? columnHelper.accessor('user', {
    id: 'city',
    header: i18n.t('city'),
    cell: (d) => d.getValue()?.city,
    size: width.city
  }) : null,

  ['root', 'solar_admin', 'property_management'].includes(myRoleType) ? columnHelper.accessor('company', {
    id: 'company',
    header: i18n.t('PV Installer'),
    cell: (d) => d.getValue()?.name || i18n.t('noComp'),
    size: width.company
  }) : null,

  additionalColumns.oem ? columnHelper.accessor('connectedOem', {
    id: 'connectedOem',
    header: i18n.t('oem'),
    cell: (d) => (d.getValue() ? d.getValue()?.name : i18n.t('NoOEM')),
    size: width.company
  }) : null,

  additionalColumns.license ? columnHelper.accessor('license', {
    id: 'license',
    header: i18n.t('License'),
    cell: (d) => d.getValue()?.name,
    size: width.license
  }) : null,

  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: i18n.t('Creation Date'),
    cell: (d) => RenderDate({ value: d.getValue() }),
    size: width.createdAt,
    meta: {
      tdClasses: 'creation-date-td'
    }
  }),

  columnHelper.accessor('lastErrorDate', {
    id: 'lastErrorDate',
    header: i18n.t('lastErrorDate'),
    cell: (d) => RenderDate({ value: d.getValue() }),
    size: width.lastErrorDate,
    meta: {
      tdClasses: 'last_error_date-td'
    }
  }),

  columnHelper.accessor('assigned', {
    id: 'assigned',
    header: i18n.t('assigned'),
    cell: (d) => RenderAssignedStatus({
      original: d.row.original
    }, ['property_management'].includes(myRoleType)),
    size: width.assigned,
    meta: {
      tdClasses: 'assigned-td'
    }
  }),

  columnHelper.accessor('priority', {
    id: 'priority',
    header: i18n.t('priority'),
    cell: (d) => RenderPriorityStatus({ value: d.getValue(), original: d.row.original }),
    size: width.priority,
    meta: {
      tdClasses: 'priority-td'
    }
  }),

  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('workStatus'),
    cell: (d) => RenderWorkStatus({ value: d.getValue(), original: d.row.original }),
    size: width.status,
    meta: {
      tdClasses: 'work_status-td'
    }
  }),

  additionalColumns.last_login && columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    cell: (d) => RenderLastLogin(d.row),
    size: width.last_login,
    meta: {
      tdClasses: 'last_login-td'
    }
  }),

  additionalColumns.installationDate && columnHelper.accessor('user', {
    id: 'installationDate',
    header: i18n.t('installationDate'),
    cell: (d) => RenderDate({ value: d.getValue()?.createdAt }),
    size: width.installationDate,
    meta: {
      tdClasses: 'installation_date-td'
    }
  }),

  additionalColumns.installationFinishedDate && queries.isInstallationCompleted !== false ? columnHelper.accessor('gateway', {
    id: 'installationFinishedDate',
    header: i18n.t('installationFinishedDate'),
    cell: (d) => RenderDate({ value: d.getValue()?.installationFinishedDate }),
    size: width.installationFinishedDate,
    meta: {
      tdClasses: 'installation_date-td'
    }
  }) : null,

  additionalColumns.lastStreamUpdate ? columnHelper.accessor('gateway', {
    id: 'lastStreamUpdate',
    header: i18n.t('lastConnection'),
    cell: (d) => RenderDate({ value: d.getValue()?.lastStreamUpdate }),
    size: width.lastStreamUpdate,
    meta: {
      tdClasses: 'last-update-td'
    }
  }) : null,

  additionalColumns.kWp ? columnHelper.accessor('settings', {
    id: 'kWp',
    header: i18n.t('kWPeakOutputLabel'),
    cell: (d) => {
      const value = d.getValue()?.kWp;
      return Number.isFinite(value) && `${Math.round(value * 100) / 100} ${i18n.t('kWp')}`;
    },
    size: width.kWp,
    meta: {
      tdClasses: 'last-update-td'
    }
  }) : null,

  columnHelper.display({
    id: 'buttons', // Unique identifier for the column
    header: EditColumns,
    enableResizing: false,
    cell: (d) => RenderMonitoringTableButtons(
      d.row.original,
      {
        sendEmail: (['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)),
        editNote: (['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)),
        devices: { preLink: '/users/' }
      }
    ),
    size: width.buttons ?? 80,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end action-buttons'
    },
    filterable: false,
    sortable: false
  })
]).filter(Boolean);

export default columns;
