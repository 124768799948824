import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { getErrorInfo } from '../slice';
import { RenderFullDate } from '../../../components/ReactTable/renderFunctions';
import { getMonitoringErrorsSelector } from '../../../redux-store/selectors/monitoring';
import { renderDeviceName } from '../utils';

import i18n from '../../../i18n';

import '../index.scss';
import { ERROR_MONITORING_ERRORS_URL } from '../../../api/apiUrls';
import { Tooltip } from '../../../components';

/**
 * Renders information when user expanded row
 * @param  {string} rowData - object with data about row
 */
const ExpandedRow = (props) => {
  const {
    rowData: { original }
  } = props;
  const dispatch = useDispatch();

  const errors = useSelector(getMonitoringErrorsSelector);
  const [expandedArray, setExpandedArray] = useState([]);

  useEffect(() => {
    dispatch(
      getErrorInfo({
        url: `${ERROR_MONITORING_ERRORS_URL}/${original._id}`,
        taskId: original._id
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      const foundedElement = Object.entries(errors).find((el) => el[0] === original._id);
      setExpandedArray(foundedElement ? foundedElement[1] : []);
    }
  }, [errors, original._id]);

  return (
    <div className="table-details expanded-row">
      <div className="container-fluid">
        <div className="column mt-2">
          {expandedArray?.length ? (
            <div className="col-md-11 col-lg-11 column justify-content-start table-row">
              <div className="row">
                <div className="table-row-details-head number-column">#</div>
                <div className="device-column table-row-details-head">{i18n.t('deviceName')}</div>
                <div className="signal-column table-row-details-head">{i18n.t('tag')}</div>
                <div className="signal-column table-row-details-head">{i18n.t('error')}</div>
                <div className="signal-column table-row-details-head">{i18n.t('errorDetails')}</div>
                <div className="error-date-column table-row-details-head">
                  {i18n.t('errorDate')}
                </div>
                <div className="error-status-column table-row-details-head">
                  {i18n.t('errorStatus')}
                </div>
              </div>
              {expandedArray.map((el, index) => (
                <div className="row" key={`ErrorRow_ID_${uuidv4()}`}>
                  <div className="number-column table-row-details-td">
                    {expandedArray.length - index}
                  </div>
                  <div className="device-column table-row-details-td">{renderDeviceName(el)}</div>
                  <div className="signal-column table-row-details-td">
                    {el.sensor?.tag?.name || ''}
                  </div>
                  <div className="signal-column table-row-details-td m--font-danger">
                    <Tooltip
                      disabled={!el.errorLabel}
                      title={(
                        <>
                          <p className="error-title">
                            {el.errorCode}
                            :
                            {' '}
                            {el.errorLabel || i18n.t('unknownError')}
                          </p>
                          <p className="error-subtitle">{el.errorDescription}</p>
                        </>
                      )}
                      customClasses={{ tooltip: 'error-tooltip_wrapper' }}
                    >
                      <span className="error_wrapper">{el.errorCode}</span>
                    </Tooltip>
                  </div>
                  <div className="signal-column table-row-details-td">{el.errorDetails}</div>
                  <div className="error-date-column table-row-details-td">
                    {RenderFullDate({ value: el.createdAt })}
                  </div>
                  <div className="error-status-column table-row-details-td">
                    {i18n.t(el.status)}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        {original.note ? (
          <div className="column mt-2">
            <div className="col-md-11 col-lg-11 column justify-content-start table-row">
              <div className="row ml-2">
                <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
                  {i18n.t('note')}
                </div>
              </div>
              <div className="row ml-2">
                <div className="col-12 table-row-details-td undertable-note">{original.note}</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ExpandedRow.propTypes = {
  rowData: PropTypes.instanceOf(Object).isRequired
};

export default ExpandedRow;
