// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table'; import {
  RenderEmail,
  RenderStatus,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { width }) => ([
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor('sm_id', {
    id: 'sm_id',
    header: i18n.t('SMID'),
    cell: (d) => d.getValue() || i18n.t('noSolarManager'),
    size: width.sm_id,
    meta: {
      tdClasses: 'sm_id'
    }
  }),
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'last_name',
    header: i18n.t('fName'),
    size: width.full_name
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    cell: (d) => RenderEmail({ value: d.getValue() }),
    size: width.email,
    meta: {
      tdClasses: 'email-text-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    cell: (d) => RenderStatus({ value: d.getValue() }),
    size: width.status,
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: width.buttons,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(
      d.row.original,
      {
        ...(['root', 'solar_admin', 'pv_installer', 'pv_installer_employee'].includes(myRoleType)) ? {
          editUser: { preLink: '/setting-profile/subuser/' }
        } : {},
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    )
  })
]);

export default columns;
