import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderAccess
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { width }) => [
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor('full_name', {
    id: 'full_name',
    header: i18n.t('fName'),
    cell: (d) => RenderFullName(d.row),
    size: width.full_name
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    cell: (d) => RenderEmail({ value: d.getValue() }),
    size: width.email,
    meta: {
      tdClasses: 'email-text-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: width.status,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    },
    enableSorting: false
  }),
  columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: width.access,
    cell: ({ row }) => RenderAccess(row),
    enableSorting: false
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: width.last_login,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    enableSorting: false
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: width.city,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: width.buttons,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(d.row.original, {
      devices: false,
      edit: { preLink: '/setting-profile/employee/' },
      remove: myRoleType === 'pv_installer'
    })
  })
].filter(Boolean);

export default columns;
