import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import DeviceColorButton from '../../../containers/DeviceColorButton';
import Tooltip from '../../Tooltip';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';

import i18n from '../../../i18n';

import '../index.scss';
import { isInvitedUserHasReadOnlyPermissionDashboardSelector, isPvEmployeeInstallHasReadOnlyAccessDashboardSelector } from '../../../containers/DashboardV2/selectors';
import { READ_ONLY_ROLES } from '../../../constants';

const truncateName = (value) => (value?.length > 16 ? `${((i18n.exists(value) && i18n.t(value)) || value).slice(0, 15)}...` : value);

/**
 * Renders checkbox for redux form
 * @memberof module:ReduxFormFields
 * @param  {Object} props - React component properties
 * @param  {Object} props.input
 * @param  {string} props.label - label text
 * @param  {string} props.labelClass - className of label
 * @param  {Object} props.meta
 * @param  {boolean} props.meta.touched
 * @param  {boolean} props.meta.error
 */
export const Checkbox = (props) => {
  const {
    input,
    showTooltip,
    label,
    labelClass,
    meta: { touched, error },
    languageToSync = '',
    defaultValue,
    labelCircleColor = '',
    device,
    fieldName = '',
    deviceType: _deviceType,
    deviceName: _deviceName,
    ...custom
  } = props;

  const selectValue = formValueSelector('chartSettings');

  const fieldNameColor = useSelector((state) => selectValue(state, fieldName));
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionDashboardSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessDashboardSelector);
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const localeLabel = typeof label === 'string' && i18n.exists(label) ? i18n.t(label) : label;

  return (
    <div className="has-danger">
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.exists(showTooltip) ? i18n.t(showTooltip) : showTooltip} placement="right" />
        </div>
      )}
      <label
        title={localeLabel?.length > 16 ? localeLabel : null}
        htmlFor={custom.id || custom?._id}
        className={`m-checkbox m-checkbox--air m-checkbox--state-warning ${labelClass}`}
      >
        <input {...input} {...custom} />
        {fieldName ? truncateName(localeLabel) : localeLabel}
        <span />
        {touched && (error || languageToSync) && (
          <div className="inputCustomWarning">{i18n.t(error)}</div>
        )}
      </label>
      {fieldName ? (
        <DeviceColorButton
          device={device}
          fieldName={fieldName}
          disabled={
            ([
              'show_consumption-color',
              'peak_consumption-color',
              'show_production-color',
              'peak_production-color',
              'additional_battery_curve-color'
            ].includes(fieldName)
              || isReadOnlyRole)
            || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess
          }
          deviceColor={fieldNameColor || device?.tag?.color || labelCircleColor}
          form="chartSettings"
        />
      ) : null}
    </div>
  );
};

Checkbox.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  labelClass: PropTypes.string,
  languageToSync: PropTypes.string,
  showTooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labelCircleColor: PropTypes.string,
  device: PropTypes.instanceOf(Object),
  fieldName: PropTypes.string,
  defaultValue: PropTypes.bool,
  deviceType: PropTypes.string,
  deviceName: PropTypes.string
};
