import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { CancelButton } from '../../../components/UIKit';
import { getModalsSearchIPSelector } from '../../../redux-store/selectors/modals';
import { getUserDevicesIpsSelector, getUserDevicesLoadSelector } from '../../../redux-store/selectors/userDevices';
import { titleForIpModalWindowSelector } from '../selectors';
import { Preloader } from '../../../components';
import { SEARCH_IP_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

const SearchIPWindow = (props) => {
  const { reset } = props;
  const dispatch = useDispatch();

  const ips = useSelector(getUserDevicesIpsSelector);
  const modal = useSelector(getModalsSearchIPSelector);
  const title = useSelector(titleForIpModalWindowSelector);
  const isLoading = useSelector(getUserDevicesLoadSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: SEARCH_IP_MODAL_ID }));

  const onClickHandler = (ip) => {
    const formID = modal?.data?.formID;
    if (formID) {
      dispatch(change(formID, 'ip', ip));
    }
    handleOnClose();
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow isModalOver modalID={SEARCH_IP_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className={`modal-body ${isLoading ? 'm-0' : ''}`}>
        {isLoading ? <Preloader /> : null}
        {ips ? (
          <ul className="ip-list">
            {ips.map((_data) => (
              <li key={uuidv4()} className="nav-item m-tabs__item ip-item">
                <span>
                  <a
                    className="nav-link m-tabs__link ip-link"
                    onClick={onClickHandler.bind(null, _data.ip)}
                  >
                    <span className="ip-name">{_data.hostname || _data.ip}</span>
                    <br />
                    <span className="ip-value">ip: &nbsp;</span>
                    {_data.ip}

                    {Boolean(_data.mac) && (
                      <>
                        <br />
                        <span className="ip-value">MAC: &nbsp;</span>
                        {_data.mac}
                      </>
                    )}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
      </div>
    </ModalWindow>
  );
};

SearchIPWindow.propTypes = {
  reset: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'searchIPform'
})(SearchIPWindow);

export default form;
