/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Tooltip from '../../Tooltip';
import { useForceUpdate } from '../../../hooks';

import i18n from '../../../i18n';

import './styles.scss';

const isChrome = window?.browser?.name === 'Chrome';

/**
 * Renders date input for redux form
 * @memberof module:ReduxFormFields
 */
export const CustomDateInput = (props) => {
  const {
    input,
    label = '',
    showTooltip = '',
    placement,
    meta: { touched, error },
    preventAuto,
    className,
    defaultValue,
    languageToSync = '',
    isMui = false,
    ...custom
  } = props;

  const [timeout, setNewTimeout] = useState(null);
  const myInputRef = useRef();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (isChrome) {
      setNewTimeout(setTimeout(() => forceUpdate(), 1000));
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelProps = {
    className: 'inputLabel',
    ...(isChrome && myInputRef?.current?.matches(':-webkit-autofill')) ? {
      shrink: true
    } : {}
  };

  if (isMui) {
    const value = moment(input.value);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateField
          sx={{ width: 120 }}
          inputRef={myInputRef}
          label=""
          {...custom}
          {...input}
          onBlur={() => {
            input.onBlur();
          }}
          onChange={(date) => {
            if (date?.isValid?.()) {
              input.onChange(date.valueOf());
            }
          }}
          value={value}
          format="DD.MM.YYYY"
        />
        {touched && (error || languageToSync) && (
          <div className="inputCustomWarning custom-date-input-warning">
            {i18n.t(error)}
          </div>
        )}
      </LocalizationProvider>
    );
  }

  return (
    <>
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="left" />
        </div>
      )}
      <TextField
        inputRef={myInputRef}
        hiddenLabel={!label}
        label={label}
        defaultValue={defaultValue}
        {...input}
        {...custom}
        className={`customDateInputField ${className || ''}`}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'datepicker-input'
          }
        }}
        inputProps={
          preventAuto
            ? {
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'off'
            }
            : {}
        }
        InputLabelProps={labelProps}
        variant="outlined"
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning custom-date-input-warning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

CustomDateInput.propTypes = {
  isMui: PropTypes.bool,
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.string,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool
};
