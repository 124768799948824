import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { required, requiredMultiple } from '../../validation';
import { MultipleSelect, CustomSelect } from '../../../components/ReduxFormFields';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { additionalFields } from '../utils';

import { affectedDevicesOptionsSelector, inputDevicesOptionsSelector } from './selectors';
import { getModalsIsLoadingSelector, getModalsSelector } from '../../../redux-store/selectors/modals';
import { ADD_DEVICE_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import './styles.scss';

/**
 * AddLoadGuardWindow form id
 */
const formID = 'addDeviceForm';

/**
 * Modal window to add load guard devices
 * @memberof module:UserDevices
 */
const AddLoadGuardWindow = (props) => {
  const { initialize, handleSubmit, title = '' } = props;
  const dispatch = useDispatch();

  const modals = useSelector(getModalsSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const inputDevicesOptions = useSelector(inputDevicesOptionsSelector);
  const affectedDevicesOptions = useSelector(affectedDevicesOptionsSelector);

  const modal = modals?.[ADD_DEVICE_MODAL_ID];

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_DEVICE_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      initialize();
    }
  }, [modal]); // eslint-disable-line

  return (
    <ModalWindow modalID={ADD_DEVICE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              noValidate
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
              id={formID}
            >
              <div className="form-group m-form__group input-field zIndex20">
                <div className="flags-select-label">{`${i18n.t('inputDevice')} *`}</div>
                <Field
                  name="inputDevice"
                  component={CustomSelect}
                  placeholder={i18n.t('selectInputDevice')}
                  options={inputDevicesOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                />
              </div>
              <div className="form-group m-form__group input-field zIndex19">
                <Field
                  name="inputDeviceFunction"
                  {...additionalFields.inputDeviceFunction}

                />
              </div>
              <div className="form-group m-form__group flex-multiple-select">
                <Field
                  label={`${i18n.t('affectedDevices')} *`}
                  name="affectedDevices"
                  component={MultipleSelect}
                  placeholder={i18n.t('selectAffectedDevices')}
                  options={affectedDevicesOptions}
                  validate={[requiredMultiple]}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} isSubmit type="save" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

AddLoadGuardWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  initialize: PropTypes.func.isRequired
};

const form = reduxForm({
  form: formID
})(AddLoadGuardWindow);

export default form;
