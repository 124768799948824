import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedFiles, getDocumentationFiles, downloadFiles } from './store/slice';
import { closeModalWindow, DOWNLOAD_FILE_MODAL_ID, openModalWindow } from '../ModalWindow/slice';
import {
  getDocumentationFilesLoadingSelector,
  getDocumentationFilesSelector,
  getDocumentationSelectedFilesSelector
} from '../../redux-store/selectors/documentation';
import { getModalsDownloadFileDataIDSelector } from '../../redux-store/selectors/modals';
import { DocumentationTableHeader, UploadModal, LImitSizeModal, DeleteFilesModal } from './components';
import { Breadcrumbs, Preloader, TopBar } from '../../components';
import { DELETE_FILES_MODAL_ID } from './constants';
import { getColumns } from './columns';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../redux-store/selectors/userDevices';
import { clearUserData, getUserInfo } from '../UserDetails/slice';
import { ReactTableV2 } from '../../components/ReactTableV2';

import i18n from '../../i18n';
import './index.scss';

const Documentation = (props) => {
  const { match, myRoleType } = props;
  const { userId } = match?.params || {};
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (myRoleType !== 'end_user') {
      dispatch(getUserInfo({ userId }));
    }

    return () => {
      dispatch(clearUserData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const files = useSelector(getDocumentationFilesSelector);
  const isFilesLoading = useSelector(getDocumentationFilesLoadingSelector);
  const downloadFilesId = useSelector(getModalsDownloadFileDataIDSelector);
  const selectedRowsIds = useSelector(getDocumentationSelectedFilesSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  const [selectedAll, setSelectedAll] = useState(false);

  const columns = useMemo(() => getColumns(myRoleType, isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess), [myRoleType, isReadOnlyPermission, isPvEmployeeInstallHasReadOnlyAccess, userId]); // eslint-disable-line

  const crumbs = myRoleType === 'end_user' ? [
    { name: i18n.t('settings'), url: '/my-devices' },
    { name: i18n.t('documentationTitle') }
  ] : [
    { name: i18n.t('SMUs'), url: '/users' },
    { name: i18n.t('settings'), url: `/users/${userId}` },
    { name: i18n.t('documentationTitle') }
  ];

  const handlerResetSelected = () => {
    dispatch(setSelectedFiles({ selectedFiles: [] }));
    setSelectedAll(false);
  };

  const handlerToggleSelection = (getOldState) => {
    const oldSelection = selectedRowsIds.reduce((acc, el) => {
      acc[el] = true;
      return acc;
    }, {});
    const selection = getOldState(oldSelection);
    dispatch(setSelectedFiles({ selectedFiles: Object.keys(selection) }));
  };

  const handlerDeleteFiles = () => dispatch(openModalWindow({ modalID: DELETE_FILES_MODAL_ID, data: { ids: selectedRowsIds } }));

  useEffect(() => {
    if (downloadFilesId) {
      dispatch(downloadFiles({ filesIds: [downloadFilesId] }));
      dispatch(closeModalWindow({ modalID: DOWNLOAD_FILE_MODAL_ID }));
    }
  }, [downloadFilesId, userId]);// eslint-disable-line

  useEffect(() => {
    if (userId) {
      dispatch(getDocumentationFiles({ userId }));
    }
  }, [userId]);// eslint-disable-line

  useEffect(() => {
    const isResetSelectedAll = !selectedRowsIds.length && selectedAll;

    if (isResetSelectedAll) {
      setSelectedAll(false);
    }
  }, [files, selectedRowsIds]);// eslint-disable-line

  return (
    <>
      <TopBar updateButton showNavigation />
      {isFilesLoading ? (<Preloader />) : (
        <div className="main-table-container documentation-wrapper">
          <UploadModal userId={userId} />
          <LImitSizeModal />
          <DeleteFilesModal />
          <Breadcrumbs crumbs={crumbs} />
          <DocumentationTableHeader
            filesCount={files.length}
            selectedRowsIds={selectedRowsIds}
            resetSelectedRows={handlerResetSelected}
            deleteFiles={handlerDeleteFiles}
            myRoleType={myRoleType}
            isReadOnlyPermission={isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess}
          />
          <ReactTableV2
            columns={columns}
            data={files}
            setRowSelection={handlerToggleSelection}
            rowSelection={selectedRowsIds.reduce((acc, el) => {
              acc[el] = true;
              return acc;
            }, {})}
          />
        </div>
      )}
    </>
  );
};

Documentation.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired
};

export default Documentation;
