import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Tooltip } from '../../../../../components';

import i18n from '../../../../../i18n';

export const ItemsList = ({ columns }) => columns.map((column) => (
  <ul className="list grid" key={uuidv4()}>
    {column.map(({ title, isAllowed, Value, tooltip, noDots, titleClasses, wrapperClasses }) => {
      if (!isAllowed) return null;

      return (
        <Fragment key={title || uuidv4()}>
          {title && (
            <li className={`list-item ${wrapperClasses || ''}`}>
              <h4 className={`list-item-title ${titleClasses || ''}`}>
                {i18n.t(title)}
                {tooltip && <Tooltip title={i18n.t(tooltip)} />}
                {!noDots && ':'}
              </h4>
            </li>
          )}
          <li className="list-item"><Value /></li>
          {!title && <li />}
        </Fragment>
      );
    })}
  </ul>
));

ItemsList.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired
};
