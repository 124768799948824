import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import { closeModalWindow } from '../../containers/ModalWindow/slice';
import { CancelButton, PrimaryButton } from '../../components/UIKit';

import i18n from '../../i18n';
import './index.scss';
import { getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';
import { getPaymentUrlRequest } from '../../containers/Payments/slice';

const CkwLicenseRestrictionsModal = ({ modalID }) => {
  const dispatch = useDispatch();
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const handleClose = () => {
    dispatch(closeModalWindow({ modalID }));
  };

  const redirectToPaymentHandler = () => dispatch(
    getPaymentUrlRequest({ userId: undefined })
  );

  return (
    <ModalWindow isModalOver customOnClose={handleClose} modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t(myRoleType === 'end_user' ? 'upgradeToUnlock' : 'subscriptionLimitReached')}</h5>
      </div>

      <div className="modal-body">{i18n.t(myRoleType === 'end_user' ? 'upgradeToUnlockPopUpMessage3' : 'starterAllowsOneDevice')}</div>
      <div className="modal-footer">
        {myRoleType === 'end_user' ? (
          <>
            <CancelButton onClickHandler={handleClose} i18nLabel="later" />
            <PrimaryButton onClickHandler={redirectToPaymentHandler} i18nLabel="upgrade" />
          </>
        ) : (
          <PrimaryButton onClickHandler={handleClose} i18nLabel="ok" />
        )}
      </div>

    </ModalWindow>
  );
};

CkwLicenseRestrictionsModal.propTypes = {
  modalID: PropTypes.string.isRequired
};

export default CkwLicenseRestrictionsModal;
