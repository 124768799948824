import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { CancelButton } from '../../../components/UIKit';
import { getModalSearchChargersSelector } from '../../../redux-store/selectors/modals';
import { getUserDevicesChargersSelector, getUserDevicesLoadSelector } from '../../../redux-store/selectors/userDevices';
import { Preloader } from '../../../components';
import { closeModalWindow, SEARCH_CHARGERS_MODAL_ID } from '../../ModalWindow/slice';
import i18n from '../../../i18n';

const SearchChargersWindow = (props) => {
  const { reset } = props;
  const dispatch = useDispatch();

  const chargers = useSelector(getUserDevicesChargersSelector);
  const modal = useSelector(getModalSearchChargersSelector);
  const isLoading = useSelector(getUserDevicesLoadSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: SEARCH_CHARGERS_MODAL_ID }));

  const onClickHandler = (charger) => {
    const formID = modal?.data?.form;
    if (formID) {
      dispatch(change(formID, 'data.OCPPChargerID', charger.chargerId));
    }
    handleOnClose();
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow isModalOver modalID={SEARCH_CHARGERS_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('selectCharger')}</h5>
      </div>
      <div className={`modal-body ${isLoading ? 'm-0' : ''}`}>
        {isLoading ? <Preloader /> : null}
        {chargers.length ? (
          <ul className="ip-list">
            {chargers.map((charger) => (
              <li key={uuidv4()} className="nav-item m-tabs__item ip-item">
                <span>
                  <a
                    className="nav-link m-tabs__link ip-link"
                    onClick={onClickHandler.bind(null, charger)}
                  >
                    <span className="ip-name">{charger.name}</span>
                    <br />
                    <span className="ip-value">id: &nbsp;</span>
                    {charger.chargerId}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="noChargingFound">{i18n.t('noChargingFound')}</p>
        )}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
      </div>
    </ModalWindow>
  );
};

SearchChargersWindow.propTypes = {
  reset: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'searchChargersForm'
})(SearchChargersWindow);

export default form;
