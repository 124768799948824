import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListPagination, DataListFilterV2 } from '../DataList';
import { PrimaryButton } from '../../components/UIKit';
import { DeleteViewerWindow, AddViewerWindow } from './modals';
import { SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';

import {
  openModalWindow,
  modalWindowSend,
  ADD_DATA_MODAL_ID,
  REMOVE_DATA_MODAL_ID
} from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';

import columns from './columns';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { USERS_VIEWERS_URL, USERS_VIEWER_URL } from '../../api/apiUrls';

import i18n from '../../i18n';

import '../Users/index.scss';
import { ConfirmDeleteUserWindow } from '../../modals';
import { transformResponse } from '../../utils/transformResponse';
import { ReactTableV2 } from '../../components/ReactTableV2/ReactTableV2';

/**
 * @module ViewersTable
 */
const dataListID = 'viewers';
const listURL = USERS_VIEWER_URL;

/**
 * Renders page for viewers list
 * @memberof module:ViewersTable
 */
const Viewers = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const { [dataListID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const columnsViewer = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const defaultParams = pageSettings.queries;

  const sendAddUser = (fields) => dispatch(modalWindowSend(
    {
      modalID: ADD_DATA_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: `${USERS_VIEWERS_URL}`,
        data: {
          ...fields,
          'rep-password': undefined
        }
      },
      cb: () => dataListReloadData({ listID: dataListID })
    }));

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend(
    {
      modalID: REMOVE_DATA_MODAL_ID,
      requestConfig: {
        method: 'delete',
        url: `/user/${id}`
      },
      cb: () => dataListReloadData({ listID: dataListID })
    }));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  return (
    <div className="main-table-container">
      <AddViewerWindow onSubmit={sendAddUser} />
      <DeleteViewerWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('Viewer').toLowerCase()} />

      <TopBar updateButton showNavigation />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('viewers') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10 order-1 mr-auto">
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,company,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListID}
                  listURL={listURL}
                  transformResponse={transformResponse}
                />
              </div>
              <div className="col-auto order-2 btn-sm-us">
                <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addViewerbtn" isLargeButton />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <ReactTableV2
            columns={columnsViewer}
            listID={dataListID}
            listURL={listURL}
            defaultParams={defaultParams}
            transformResponse={transformResponse}
          />

          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
    </div>
  );
};

Viewers.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default Viewers;
