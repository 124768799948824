/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'lodash/fp/compose';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { cloneDeep, pick } from 'lodash';
import moment from 'moment';
import DataList from '../../DataList';
import { PROVIDERS_URL, USER_TARIFF_SETTINGS_URL, USER_HOUSE_SETTINGS_URL } from '../../../api/apiUrls';
import { GENERAL_SETTINGS_MODAL_ID, modalWindowSend } from '../../ModalWindow/slice';
import EditGeneralSettingsWindow from '../components/EditGeneralSettingsWindow';
import EditTariffWindow from '../components/EditTariffWindow';
import HistoricalTariffWindow from '../components/HistoricalTariffWindow';
import { HistoricalTariffsWindow } from '../components/HistoricalTariffsWindow';
import UserInfo from './components/UserInfo';
import { TabPanel, GeneralInfo, Software, SupportTab, LocalApi } from './components';
import { settingPrGetUser } from '../../SettingProfile/slice';
import { getUserInfo } from '../slice';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { setPagesSetting } from '../../PagesSettings/slice';
import i18n from '../../../i18n';
import {
  defaultStepsForSeasons,
  editTariffId,
  tariffTypes,
  unitsTypesByCountry,
  PROVIDERS_LIST_ID,
  TARIFFS_LIST_ID,
  HiSTORICAL_TARIFF_LIST_ID
} from '../constants';
import { HELION_APP_ID } from '../../../config';
import { CommonInfoModalWindow } from '../../../modals';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../../redux-store/selectors/userDevices';
import { dataListReloadData } from '../../DataList/slice';

import './index.scss';
import { signInUpdateUser } from '../../SignIn/slice';
import formatFloatSeparator from '../../../utils/formatFloatSeparator';

const providersSelector = createSelector(
  (state) => state.dataList[PROVIDERS_LIST_ID]?.data,
  (providers) => providers || []
);

const transformToNumber = (name) => (fields) => ({ ...fields, [name]: Number(fields?.[name]) });

const transformFieldsForAPI = (formFields) => {
  const composer = compose(...['offsetWatt', 'kWp', 'houseFuse'].map(transformToNumber));

  if (Number(formFields?.houseFuse) > 0) {
    return composer({ ...formFields });
  }

  return composer({ ...formFields, loadManagement: false });
};

const transformTariffFieldsForAPI = (formFields, state, providers, initialFrom) => {
  const cloneFormFields = cloneDeep({ ...formFields });

  const fillHelionExport = () => {
    const returnValue = {};
    if (cloneFormFields?.helionExportEnergy?.tariffType === tariffTypes.standard) {
      returnValue.exportEnergy = pick(formFields.helionExportEnergy, ['tariffType', 'singleTariff', 'tariffPriceCurrency']);
    }

    if (cloneFormFields?.helionExportEnergy?.tariffType === tariffTypes.dynamic) {
      returnValue.exportEnergy = pick(formFields.helionExportEnergy, ['tariffType', 'tariffPriceCurrency']);
      returnValue.exportEnergy.dynamicTariff = { provider: 'epex-spot', providerType: 'simple' };
    }

    if (cloneFormFields?.helionExportEnergy?.tariffType === tariffTypes.fix5) {
      returnValue.exportEnergy = pick(formFields.helionExportEnergy, ['tariffType', 'tariffPriceCurrency']);
      returnValue.exportEnergy.singleTariff = {};
    }
    if (cloneFormFields?.helionExportEnergy?.tariffType === tariffTypes.fix10) {
      returnValue.exportEnergy = pick(formFields.helionExportEnergy, ['tariffType', 'tariffPriceCurrency']);
      returnValue.exportEnergy.singleTariff = {};
    }

    return returnValue;
  };

  const fillData = (type) => {
    let returnValue = {};

    if ([tariffTypes.standard, tariffTypes.fix5, tariffTypes.fix10].includes(cloneFormFields?.[type]?.tariffType)) {
      return fillHelionExport();
    }

    if (cloneFormFields?.[type].tariffType === tariffTypes.single) {
      returnValue = pick(cloneFormFields, [`${type}.singleTariff`, `${type}.tariffType`, `${type}.tariffPriceCurrency`]);
    }

    if (cloneFormFields?.[type].tariffType === tariffTypes.double) {
      returnValue = pick(cloneFormFields, [`${type}.doubleTariff`, `${type}.tariffType`, `${type}.tariffPriceCurrency`]);
    }

    if (cloneFormFields?.[type]?.tariffType === tariffTypes.dynamic) {
      const provider = providers.find(({ value }) => value === cloneFormFields?.[type]?.dynamicTariff.provider);
      returnValue = pick(cloneFormFields, [`${type}.dynamicTariff`, `${type}.tariffType`, `${type}.tariffPriceCurrency`]);
      returnValue[type].dynamicTariff.oauth2TokenId = cloneFormFields[`${type}Oauth`]?.oauth2TokenId || undefined;
      returnValue[type].dynamicTariff.providerType = provider?.type || 'simple';
      returnValue[type].dynamicTariff.meteringCode = returnValue[type].dynamicTariff.meteringCode || undefined;
      returnValue[type].dynamicTariff.price = undefined;
    }

    if (!returnValue[type].tariffPriceCurrency) {
      returnValue[type].tariffPriceCurrency = 'CHF/kWh';
    }

    return returnValue;
  };

  const exportEnergy = formFields.isHelionUser
    ? fillHelionExport()
    : fillData('exportEnergy');

  const data = {
    ...exportEnergy,
    ...fillData('importEnergy'),
    from: cloneFormFields.saveAsNewTariff
      && state === 'active'
      && moment(cloneFormFields.from).isSame(moment(initialFrom), 'day')
      ? moment().utcOffset(0, true).startOf('day').toISOString()
      : moment(cloneFormFields.from).utcOffset(0, true).toISOString(),
    state
  };

  return formatFloatSeparator(data);
};

/**
* Block with user, software and general info. Viewed by everyone except end_user.
*/
const UserDetailsTabs = (props) => {
  const { user = {}, myRoleType } = props;
  const dispatch = useDispatch();
  const { userDetails: userDetailsSettings = {} } = useSelector(getPagesSettingsSelector);
  const providers = useSelector(providersSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);
  // eslint-disable-next-line arrow-body-style
  const updateSettings = () => {
    return myRoleType === 'end_user'
      ? settingPrGetUser({ userID: undefined, myself: true, roleLink: 'solar-managers-users', successfulCB: (result) => dispatch(signInUpdateUser({ user: result })) })
      : getUserInfo({ userId: user._id });
  };

  const changeTariffSettings = (fields, state = 'active') => {
    const requestConfig = {
      method: fields.saveAsNewTariff ? 'post' : 'put',
      url: `${USER_TARIFF_SETTINGS_URL}/${user._id}${fields.saveAsNewTariff ? '' : `/${fields._id}`}`,
      data: transformTariffFieldsForAPI(fields, state, providers, user?.tariffSettings?.from)
    };

    dispatch(
      modalWindowSend({
        modalID: state === 'historic' ? HiSTORICAL_TARIFF_LIST_ID : editTariffId,
        requestConfig,
        cb: () => {
          dispatch(dataListReloadData({ listID: TARIFFS_LIST_ID }));

          return updateSettings();
        }
      })
    );
  };

  const updateHouseSettings = (fields) => dispatch(
    modalWindowSend({
      modalID: GENERAL_SETTINGS_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: `${USER_HOUSE_SETTINGS_URL}${user._id ? `/${user._id}` : ''}`,
        data: transformFieldsForAPI(fields)
      },
      cb: updateSettings
    })
  );

  const deleteTariff = (tariffId) => {
    dispatch(
      modalWindowSend({
        modalID: HiSTORICAL_TARIFF_LIST_ID,
        requestConfig: {
          method: 'delete',
          url: `${USER_TARIFF_SETTINGS_URL}/${user._id}/${tariffId}`
        },
        cb: () => dataListReloadData({ listID: TARIFFS_LIST_ID })
      })
    );
  };

  const handleTabChange = (_, value) => dispatch(setPagesSetting({ page: 'userDetails', data: { userInfoActiveTab: value } }));

  const tabProps = {
    user,
    myRoleType,
    isReadOnlyPermission: isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess || myRoleType === 'pv_installer_employee_read_only'
  };

  const initialTariffValues = {
    ...pick(user?.tariffSettings, ['exportEnergy', 'importEnergy', 'from', '_id']),
    from: moment(user?.tariffSettings?.from).valueOf(),
    exportEnergy: {
      ...user?.tariffSettings?.exportEnergy,
      doubleTariff: {
        ...defaultStepsForSeasons,
        ...user?.tariffSettings?.exportEnergy?.doubleTariff ?? {}
      }
    },
    importEnergy: {
      ...user?.tariffSettings?.importEnergy,
      doubleTariff: {
        ...defaultStepsForSeasons,
        ...user?.tariffSettings?.importEnergy?.doubleTariff ?? {}
      }
    },
    // internal settings
    helionExportEnergy: { ...user?.tariffSettings?.exportEnergy || { tariffType: 'standard' } },
    activeTab: 'importEnergy',
    isHelionUser: user?.userApp === HELION_APP_ID
  };

  return (
    <>
      <CommonInfoModalWindow
        modalID="requireMoreTimeID"
        isShowEachTime
        label="requireMoreTimeLabel"
        info="requireMoreTimeInfo"
      />
      <EditGeneralSettingsWindow
        onSubmit={updateHouseSettings}
        initialValues={pick(user?.houseSettings, ['offsetWatt', 'kWp', 'houseFuse', 'loadManagement'])}
        enableReinitialize
      />
      {Boolean(user?.tariffSettings) && (
        <>
          <DataList listID={PROVIDERS_LIST_ID} listURL={`${PROVIDERS_URL}?userId=${user._id}`} headers={{ 'api-version': 3 }} />
          <DataList listID={TARIFFS_LIST_ID} listURL={`${USER_TARIFF_SETTINGS_URL}/${user?._id}`} />
          <HistoricalTariffsWindow />
          <HistoricalTariffWindow
            myRoleType={myRoleType}
            onSubmit={(fields) => changeTariffSettings(fields, 'historic')}
            onDelete={deleteTariff}
            user={user}
            unit={unitsTypesByCountry[user?.country?.toLowerCase()] || unitsTypesByCountry.default}
            initialValues={{ ...initialTariffValues, saveAsNewTariff: true }}
          />
          <EditTariffWindow
            onSubmit={(fields) => changeTariffSettings(fields, 'active')}
            myRoleType={myRoleType}
            user={user}
            initialValues={initialTariffValues}
            unit={unitsTypesByCountry[user?.country?.toLowerCase()] || unitsTypesByCountry.default}
          />
        </>
      )}
      <div className="user-settings">
        <Tabs
          value={userDetailsSettings.userInfoActiveTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{ px: 2 }}
        >
          <Tab disableRipple value={0} label={i18n.t('tabEndUserInfo')} />
          <Tab disableRipple value={1} label={i18n.t('generalSettings')} />
          <Tab disableRipple value={2} label={i18n.t('software')} />
          <Tab disableRipple value={3} label={i18n.t('localApi')} />
          {['root', 'solar_admin'].includes(myRoleType) && <Tab disableRipple value={4} label={i18n.t('support')} />}

        </Tabs>

        <TabPanel classes="main-info-tab" value={userDetailsSettings.userInfoActiveTab} index={0}>
          <UserInfo label={i18n.t('tabEndUserInfo')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={1}>
          <GeneralInfo label={i18n.t('generalSettings')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={2}>
          <Software label={i18n.t('software')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={3}>
          <LocalApi label={i18n.t('localApi')} {...tabProps} />
        </TabPanel>
        {['root', 'solar_admin'].includes(myRoleType) && (
          <TabPanel value={userDetailsSettings.userInfoActiveTab} index={4}>
            <SupportTab label={i18n.t('support')} {...tabProps} />
          </TabPanel>
        )}
      </div>
    </>
  );
};

UserDetailsTabs.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string
};

export default UserDetailsTabs;
