import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs, Pagination, SearchField, TopBar } from '../../components';
import { visibleWebSelector } from '../Monitoring/selectors';
import { DataListFilterV2, DataListPagination } from '../DataList';
import i18n from '../../i18n';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { OEM_EMPLOYEES_URL } from '../../api/apiUrls';
import columns from './columns';
import { PrimaryButton } from '../../components/UIKit';
import { ADD_EMPLOYEE_MODAL_ID, REMOVE_DATA_MODAL_ID, modalWindowSend, openModalWindow } from '../ModalWindow/slice';
import { AddEmployeeModalWindow, ConfirmDeleteUserWindow } from '../../modals';
import { dataListReloadData } from '../DataList/slice';
import { transformResponse } from '../../utils/transformResponse';
import { DeleteEmployeeWindow } from '../Employees/modals';
import { ReactTableV2 } from '../../components/ReactTableV2';

const DATA_LIST_ID = 'oemEmployee';

const OEMEmployees = () => {
  const visibleWeb = useSelector(visibleWebSelector);
  const dispatch = useDispatch();
  const { [DATA_LIST_ID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const OEMEmployeesColumns = React.useMemo(() => columns(pageSettings), [pageSettings]);
  const defaultParams = pageSettings.queries;
  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_EMPLOYEE_MODAL_ID, data: { roleName: 'oem_employee' } }));

  const addOemEmployee = (fields) => dispatch(modalWindowSend({
    modalID: ADD_EMPLOYEE_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: OEM_EMPLOYEES_URL,
      data: fields
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  const removeOemEmployee = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `user/${id}`
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  return (
    <>
      <ConfirmDeleteUserWindow userRole={i18n.t('employee').toLowerCase()} />
      <DeleteEmployeeWindow onSubmit={removeOemEmployee} />
      <TopBar showNavigation visibleWeb={visibleWeb} />

      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('employees') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div
                className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 mr-auto"
              >
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,city',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={DATA_LIST_ID}
                  listURL={OEM_EMPLOYEES_URL}
                  transformResponse={transformResponse}
                />
              </div>

              <div className="col-auto order-3 btn-sm-us">
                <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addEmployeebtn" isLargeButton />
              </div>

            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <ReactTableV2
            listID={DATA_LIST_ID}
            listURL={OEM_EMPLOYEES_URL}
            params={defaultParams}
            columns={OEMEmployeesColumns}
            transformResponse={transformResponse}
          />
          <DataListPagination
            Component={Pagination}
            listID={DATA_LIST_ID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <AddEmployeeModalWindow initialValues={{ role_type: 'oem_employee' }} onSubmit={addOemEmployee} />
    </>
  );
};

export default OEMEmployees;
