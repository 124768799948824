import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderHeaderWithTooltip,
  RenderUserName,
  RenderGateway,
  RenderButtons,
  RenderStatus,
  RenderSignal,
  RenderError,
  RenderDate,
  RenderPlant,
  RenderAccess
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';
import { EditColumns } from '../../components';
import { APP_ID, HELION_APP_ID } from '../../config';
import { getExpandedIcon } from '../../components/ReactTableV2';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { columns: additionalColumns, width, queries }) => ([
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  additionalColumns.sm_id && columnHelper.accessor('gateway.sm_id', {
    id: 'sm_id',
    header: i18n.t('SMID'),
    size: width.sm_id,
    cell: (d) => RenderGateway({ value: d.getValue() }),
    meta: {
      tdClasses: 'sm_id'
    }
  }),
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name} ${row.status} ${row.email}`, {
    id: 'last_name',
    header: i18n.t('fName'),
    size: width.last_name,
    cell: ({ row }) => RenderUserName(row)
  }),
  additionalColumns.note && columnHelper.accessor('note', {
    id: 'note',
    header: i18n.t('userSettingNotesLabel'),
    size: width.note
  }),
  additionalColumns.city && columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: width.city
  }),
  columnHelper.accessor('gateway.signal', {
    id: 'signal',
    header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    size: width.signal,
    cell: (d) => RenderSignal({ value: d.getValue() })
  }),
  additionalColumns.plant && columnHelper.accessor('plant', {
    id: 'plant',
    header: i18n.t('plantLabel'),
    size: width.plant,
    cell: ({ row }) => RenderPlant(row)
  }),
  columnHelper.accessor('error', {
    id: 'hasErrors',
    header: i18n.t('error'),
    size: width.hasErrors,
    cell: ({ row }) => RenderError(row)
  }),
  ['root', 'solar_admin', 'viewer', 'property_management', 'oem'].includes(myRoleType) && columnHelper.accessor('company', {
    id: 'installer',
    header: i18n.t('installer'),
    size: width.installer,
    cell: (d) => d.getValue()?.name || i18n.t('noComp')
  }),
  additionalColumns.oem && columnHelper.accessor('connectedOem', {
    id: 'connectedOem',
    header: i18n.t('oem'),
    size: width.oem,
    cell: (d) => d.getValue()?.name || i18n.t('NoOEM')
  }),
  additionalColumns.license && columnHelper.accessor('license', {
    id: 'license',
    header: i18n.t('License'),
    size: width.license,
    cell: (d) => d.getValue()?.name
  }),
  additionalColumns.license && (APP_ID === HELION_APP_ID) && columnHelper.accessor('helionAppVersion', {
    id: 'helionAppVersion',
    header: i18n.t('appVersion'),
    size: 100
  }),
  additionalColumns.access && columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: 100,
    cell: ({ row }) => RenderAccess(row)
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: width.status,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  additionalColumns.last_login && columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: width.last_login,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    }
  }),
  additionalColumns.installationDate && columnHelper.accessor('installationDate', {
    id: 'installationDate',
    header: i18n.t('installationDate'),
    size: width.installationDate,
    cell: ({ row }) => RenderDate({ value: row.original?.createdAt }),
    meta: {
      tdClasses: 'installation_date-td'
    }
  }),
  additionalColumns.installationFinishedDate && queries.isInstallationCompleted !== false && columnHelper.accessor('installationFinishedDate', {
    id: 'installationFinishedDate',
    header: i18n.t('installationFinishedDate'),
    size: width.installationFinishedDate,
    cell: ({ row }) => RenderDate({ value: row.original?.gateway?.installationFinishedDate }),
    meta: {
      tdClasses: 'installation_date-td'
    }
  }),
  additionalColumns.lastStreamUpdate && columnHelper.accessor('lastStreamUpdate', {
    id: 'lastStreamUpdate',
    header: i18n.t('lastConnection'),
    size: width.lastStreamUpdate,
    cell: ({ row }) => RenderDate({ value: row.original?.gateway?.lastStreamUpdate }),
    meta: {
      tdClasses: 'last-update-td'
    }
  }),
  additionalColumns.kWp && columnHelper.accessor('settings.kWp', {
    id: 'kWp',
    header: i18n.t('kWPeakOutputLabel'),
    size: width.kWp,
    cell: (d) => Number.isFinite(d.getValue()) && `${Math.round(d.getValue() * 100) / 100} ${i18n.t('kWp')}`,
    meta: {
      tdClasses: 'last-update-td'
    }
  }),
  columnHelper.display({
    id: 'buttons',
    header: EditColumns,
    enableResizing: false,
    size: width.buttons,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(
      d.row.original,
      {
        devices: { preLink: '/users/' },
        editUser: { preLink: '/setting-profile/solar-managers-users/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    )
  })
]).filter(Boolean);

export default columns;
