import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import './index.scss';
import { useSelector } from 'react-redux';
import { getSignInUserRoleTypeSelector, getSignInUserSelector } from '../../../redux-store/selectors/signIn';
import { getUserDevicesUserSelector } from '../../../redux-store/selectors/userDevices';
import i18n from '../../../i18n';

/**
 * Renders multiline text field for redux form
 * @memberof module:ReduxFormFields
 */
export const OcppUrlField = (props) => {
  const {
    value,
    input,
    label,
    showTooltip,
    inputProps = {},
    ...custom
  } = props;

  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const user = useSelector(getUserDevicesUserSelector);
  const signInUser = useSelector(getSignInUserSelector);
  const smid = myRoleType === 'end_user' ? signInUser.gateway.sm_id : user.gateway.sm_id;
  const url = `ws://ocpp.solar-manager.ch/${smid}
wss://ocpp.solar-manager.ch/${smid}`;

  useEffect(() => {
    input.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="ocppUrlField m-input">
      <div className="flags-select-label">
        {i18n.exists(label) ? i18n.t(label) : label}
      </div>
      <TextareaAutosize
        {...input}
        {...custom}
        {...inputProps}
        showtooltip={showTooltip}
        value={url}
        disabled
        minRows={1}
        style={{ overflow: 'auto' }}
      />
    </div>
  );
};

OcppUrlField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string,
  showTooltip: PropTypes.string,
  label: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object)
};
