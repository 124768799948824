import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../Avatar';

/**
 * Render function for React-Table. Column for user full name (with Avatar).
 * @memberof module:ReactTable
 * @param  {object} props - General props object
 * @param  {object} props.original - object with properties to render Full Name and Avatar of user
 * @param  {string} props.original.first_name - User's or company representative first name
 * @param  {string} props.original.last_name - User's or company representative last name
 * @param  {object} props.original.avatar - Includes property url(link to user/company avatar)
 */
const RenderFullName = ({ original: { first_name, last_name, avatar } }) => (
  <div className="d-flex align-items-center">
    <Avatar
      className="small full_name-avatar-td"
      name={`${first_name} ${last_name}`}
      avatar={avatar ? avatar.url : false}
    />
    {first_name}
    {' '}
    {last_name}
  </div>
);

RenderFullName.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderFullName;
