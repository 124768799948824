import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import { FormSection } from 'redux-form';
import { Expander } from '../../../components';
import { HelionExportTariffSection } from './EditTariffWindow/HelionExportTariffSection';
import { SingleTariff } from './EditTariffWindow/SingleTariff';
import { DoubleTariff } from './EditTariffWindow/DoubleTariff';
import { DynamicTariff } from './EditTariffWindow/DynamicTariff';
import { tariffTypes } from '../constants';

import { ControlSection } from './EditTariffWindow/ControlSection';

import i18n from '../../../i18n';

export const TariffFormCommonContent = ({
  type = 'active',
  formValues,
  myRoleType,
  unit,
  onTabSelect,
  isHelionUser,
  activeTab,
  valid,
  initialValues,
  user,
  tariffTypeImport,
  tariffTypeExport,
  formID
}) => (
  <>
    <HelionExportTariffSection formValues={formValues} myRoleType={myRoleType} unit={unit} />
    {!isHelionUser && (
      <ButtonGroup
        className="tariff-tabs"
        variant="contained"
        aria-label="Basic button group"
        sx={{
          display: 'flex',
          borderRadius: 2,
          height: 30,
          boxShadow: 'none',
          opacity: valid ? 1 : 0.5
        }}
      >
        <Button
          color={activeTab === 'importEnergy' ? 'primary' : 'secondary'}
          onClick={() => onTabSelect('importEnergy')}
          sx={{
            flex: 1,
            fontSize: 12,
            textTransform: 'none',
            cursor: valid ? 'pointer' : 'not-allowed'
          }}
        >
          {i18n.t('purchaseTariff')}
        </Button>
        <Button
          color={activeTab === 'exportEnergy' ? 'primary' : 'secondary'}
          onClick={() => onTabSelect('exportEnergy')}
          sx={{
            flex: 1,
            fontSize: 12,
            textTransform: 'none',
            cursor: valid ? 'pointer' : 'not-allowed'
          }}
        >
          {i18n.t('feedInTariff')}
        </Button>
      </ButtonGroup>
    )}
    <Expander expanded={activeTab === 'importEnergy'}>
      <FormSection name="importEnergy">
        <ControlSection />
        <Expander expanded={tariffTypeImport === tariffTypes.single}>
          <SingleTariff unit={unit} skipValidation={tariffTypeImport !== tariffTypes.single} />
        </Expander>
        <Expander expanded={tariffTypeImport === tariffTypes.double}>
          <DoubleTariff
            formValues={formValues.importEnergy?.doubleTariff}
            unit={unit}
            skipValidation={tariffTypeImport !== tariffTypes.double}
          />
        </Expander>
        <Expander expanded={tariffTypeImport === tariffTypes.dynamic}>
          <DynamicTariff
            type={type}
            unit={unit}
            formValues={formValues}
            initialValues={initialValues?.importEnergy?.dynamicTariff}
            user={user}
            skipValidation={tariffTypeImport !== tariffTypes.dynamic}
            field="importEnergy"
            formID={formID}
          />
        </Expander>
      </FormSection>
    </Expander>
    {!isHelionUser && (
      <Expander expanded={activeTab === 'exportEnergy'}>
        <FormSection name="exportEnergy">
          <ControlSection />
          <Expander expanded={tariffTypeExport === tariffTypes.single}>
            <SingleTariff unit={unit} skipValidation={tariffTypeExport !== tariffTypes.single} />
          </Expander>
          <Expander expanded={tariffTypeExport === tariffTypes.double}>
            <DoubleTariff
              formValues={formValues.exportEnergy?.doubleTariff}
              unit={unit}
              skipValidation={tariffTypeExport !== tariffTypes.double}
            />
          </Expander>
          <Expander expanded={tariffTypeExport === tariffTypes.dynamic}>
            <DynamicTariff
              formValues={formValues}
              initialValues={initialValues?.exportEnergy?.dynamicTariff}
              user={user}
              unit={unit}
              skipValidation={tariffTypeExport !== tariffTypes.dynamic}
              field="exportEnergy"
              formID={formID}
            />
          </Expander>
        </FormSection>
      </Expander>
    )}
  </>
);

TariffFormCommonContent.propTypes = {
  formValues: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string,
  unit: PropTypes.string,
  onTabSelect: PropTypes.func,
  isHelionUser: PropTypes.bool,
  activeTab: PropTypes.string,
  valid: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  tariffTypeImport: PropTypes.string,
  tariffTypeExport: PropTypes.string,
  type: PropTypes.string,
  formID: PropTypes.string.isRequired
};
