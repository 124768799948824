import React from 'react';

export const getExpandedIcon = (row) => (
  <button
    type="button"
    className="react-table-expander"
    onClick={row.getToggleExpandedHandler()}
    style={{
      cursor: 'pointer',
      transform: row.getIsExpanded() ? 'rotate(90deg)' : 'none',
      transition: 'transform 0.2s ease'
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M9.1875 17.4375L14.8125 11.8125L9.1875 6.1875L9.1875 17.4375Z" fill="#898b96" />
    </svg>
  </button>
);
