import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = [];

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: {
      reducer(state, action) {
        const { type, text, id } = action.payload;
        state.push({ id, type, text });
      },
      prepare: (data) => ({ payload: { ...data, id: uuidv4() } })
    },
    deleteNotification: (state) => [...state.slice(1)]
  }
});

export const {
  addNotification,
  deleteNotification
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
