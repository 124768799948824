import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RadioButtons } from '../../../../components/ReduxFormFields';

import i18n from '../../../../i18n';

const AccessRole = ({ roleName, customFieldName }) => {
  const employeeRole = roleName?.startsWith('pv_installer_employee') ? 'pv_installer_employee' : 'oem_employee';
  const accessRoleTypeOptions = [
    {
      label: <span className="dataSMID">{i18n.t('accessReadOnly')}</span>,
      value: `${employeeRole}_read_only`
    },
    {
      label: <span className="dataSMID">{i18n.t('readAndInstall')}</span>,
      value: `${employeeRole}_read_install`
    },
    {
      label: <span className="dataSMID">{i18n.t('accessReadAndWrite')}</span>,
      value: employeeRole
    }
  ];

  if (!roleName?.startsWith('pv_installer_employee')) {
    accessRoleTypeOptions.splice(1, 1);
  }

  return (
    <div className="d-flex flex-column">
      <h6 className="freq-text">
        {i18n.t('access')}
        :
      </h6>
      {roleName ? (
        <Field
          name="role_type"
          component={RadioButtons}
          props={{ variants: accessRoleTypeOptions }}
        />
      ) : (
        <Field
          name={customFieldName || 'permission'}
          component={RadioButtons}
          props={{
            variants: [
              {
                label: <span className="dataSMID">{i18n.t('accessReadOnly')}</span>,
                value: 'read'
              },
              {
                label: <span className="dataSMID">{i18n.t('accessReadAndWrite')}</span>,
                value: 'read_and_write'
              }
            ]
          }}
        />
      )}

    </div>
  );
};

AccessRole.propTypes = {
  roleName: PropTypes.string,
  customFieldName: PropTypes.string
};

export default AccessRole;
