import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeEnergyConnectID,

  // R
  reqSMID: view.reqEnergyConnectID,

  // S
  SMID: view.EnergyConnectID,
  SMIDTolltip: view.EnergyConnectIDTolltip
};

export default replace;
