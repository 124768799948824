import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';

import DevicesTable from '../../../DevicesTable';
import i18n from '../../../../i18n';
import { PrimaryButton, ZohoHoc } from '../../../../components/UIKit';
import { houseFuseSelector, loadManagementSelector, supportEmailSubjectSelector, visibleWebSelector } from '../../selectors';
import { SUPPORT_EMAIL } from '../../../../config';
import { openModalWindow, modalWindowSend, DATA_EXPORT_MODAL_ID, DATA_EXPORT_INFO_POPUP, DATA_IMPORT_MODAL_ID } from '../../../ModalWindow/slice';
import { Breadcrumbs, TopBar } from '../../../../components';
import { DataExportModalWindow } from '../../../../modals';
import UserDetailsTabs from '../../UserDetailsTabs';
import { DATA_EXPORT_GATEWAY_DATA_URL } from '../../../../api/apiUrls';

import './index.scss';
import DataExportInfoPopUp from '../../../../modals/DataExportInfoPopUp';
import { dataExportZohoLink, dataImportZohoLink } from '../../constants';
import DataImportModal from '../../../../modals/DataImportModal';

const allowToShowNewSupportBtn = [
  'root',
  'solar_admin',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'pv_installer_employee_read_install',
  'oem',
  'oem_employee',
  'oem_employee_read_only'
];

const allowedRolesForDataImport = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'oem', 'oem_employee'];

/**
 * This layout is rendered when page is being viewed by one of ('solar_admin', 'pv_installer', 'pv_installer_employee', 'viewer', 'property_management')
 * @memberof module:UserDetails
 */
const ViewByAdmins = (props) => {
  const { myRoleType, user, userID } = props;
  const dispatch = useDispatch();

  const houseFuse = useSelector(houseFuseSelector);
  const visibleWeb = useSelector(visibleWebSelector);
  const loadManagement = useSelector(loadManagementSelector);
  const emailSubject = useSelector(supportEmailSubjectSelector);
  const isDataImportAllowed = (allowedRolesForDataImport.includes(myRoleType) && user?.permission !== 'read') || (!['viewer'].includes(myRoleType) && user?.permission === 'read_and_write');

  const sendEndUserDataExportDateRange = (fields) => {
    const { fromDate, toDate } = fields;
    const from = new Date(fromDate).toISOString();
    const to = new Date(toDate).toISOString();

    dispatch(openModalWindow(
      { modalID: DATA_EXPORT_INFO_POPUP, data: { descriptionLabelKey: 'dataExportInfoPopUpMessage' } }
    ));

    return dispatch(modalWindowSend({
      modalID: DATA_EXPORT_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: DATA_EXPORT_GATEWAY_DATA_URL,
        data: {
          gatewayIds: [user?.gateway?._id],
          from,
          to
        }
      }
    }));
  };

  const exportDataHandler = () => dispatch(openModalWindow(
    { modalID: DATA_EXPORT_MODAL_ID }
  ));

  const importDataHandler = () => dispatch(openModalWindow(
    { modalID: DATA_IMPORT_MODAL_ID }
  ));

  return (
    <>
      <TopBar showNavigation visibleWeb={visibleWeb} />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('SMUs'), url: '/users' }, { name: i18n.t('settings') }]} />

        <UserDetailsTabs user={user} myRoleType={myRoleType} />
        <div className="d-flex justify-content-end order-3 btn-sm-us export-button">
          {allowToShowNewSupportBtn.includes(myRoleType) && (
            <Tooltip
              title={<div className="support-btn-container">{i18n.t('newSupportTooltip')}</div>}
              classes={{ tooltip: 'support-btn-tooltip', popper: 'support-btn-popper' }}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
            >
              <a href={`mailto:${SUPPORT_EMAIL}?subject=${emailSubject}`} className="support-btn">
                <i className="las la-headset headset-icon" />
                {i18n.t('newSupportBtnTitle')}
              </a>
            </Tooltip>
          )}
          <ZohoHoc zohoLink={dataExportZohoLink}>
            <PrimaryButton onClickHandler={exportDataHandler} i18nLabel="exportData" isLargeButton type="upload" />
          </ZohoHoc>
          {isDataImportAllowed && (
            <ZohoHoc zohoLink={dataImportZohoLink}>
              <PrimaryButton onClickHandler={importDataHandler} i18nLabel="importData" isLargeButton type="import" />
            </ZohoHoc>
          )}
        </div>
      </div>
      <DevicesTable userId={userID} myRoleType={myRoleType} license={user?.license} userApp={user?.userApp} />
      {user.gateway && (
        <div className="container-fluid">
          <Link
            to={`/dashboard/${userID}/`}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right dashboard-btn white"
          >
            {i18n.t('dashbo')}
          </Link>
          <Link
            to={`/load-management-dashboard/${userID}/`}
            className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right dashboard-btn white mr-4 ${(!houseFuse || !loadManagement) && 'dashboard-btn-disabled'}`}
          >
            {i18n.t('loadManagementBtn')}
          </Link>
        </div>
      )}
      <DataExportModalWindow
        initialValues={{
          fromDate: moment().subtract(1, 'month').startOf('day').valueOf(),
          toDate: moment().valueOf()
        }}
        modalID={DATA_EXPORT_MODAL_ID}
        onSubmit={sendEndUserDataExportDateRange}
      />
      <DataExportInfoPopUp />
      <DataImportModal modalID={DATA_IMPORT_MODAL_ID} />
    </>
  );
};

ViewByAdmins.propTypes = {
  myRoleType: PropTypes.string,
  user: PropTypes.instanceOf(Object).isRequired,
  userID: PropTypes.string.isRequired
};

export default ViewByAdmins;
