import { createColumnHelper } from '@tanstack/react-table';
import { RenderDate, RenderStatus } from '../../../components/ReactTable/renderFunctions';
import { getExpandedIcon } from '../../../components/ReactTableV2';
import i18n from '../../../i18n';

const columnHelper = createColumnHelper();

const columnsEmp = () => [
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  columnHelper.accessor('oem.name', {
    id: 'oemCompany',
    header: i18n.t('company'),
    size: 180,
    cell: (d) => d.getValue() || 'No company'
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: 160,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: 169,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: 107,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    }
  })
].filter(Boolean);

export default columnsEmp;
