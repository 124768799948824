/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';
import { defaultPersistState } from '../../persist-migration';

const pagesSettingsSlice = createSlice({
  name: 'pagesSettings',
  initialState: defaultPersistState,
  reducers: {
    setPagesSetting: (state, action) => {
      const { page, data } = action.payload;

      state[page] = { ...state[page], ...data };
    },
    setPagesSettingQueries: (state, action) => {
      const { page, data } = action.payload;
      if (state[page]?.queries) {
        state[page].queries = { ...state[page].queries, ...data };
      } else {
        state[page] = { queries: data, width: {} };
      }
    },
    setPagesSettingColumns: (state, action) => {
      const { page, data } = action.payload;

      state[page].columns = { ...state[page].columns, ...data };
    },
    setPagesSettingColumnsWidth: (state, action) => {
      const { page, data } = action.payload;

      state[page].width = { ...state[page].width, ...data };
    }
  }
});

export const {
  setPagesSetting,
  setPagesSettingQueries,
  setPagesSettingColumns,
  setPagesSettingColumnsWidth
} = pagesSettingsSlice.actions;

export default pagesSettingsSlice.reducer;
