import { APP_ID, BKW_APP_ID } from '../../../../config';

export const PRESET_COLORS = {
  Battery: '#3f99cc',
  'Water Heater': '#f66400',
  'Smart Plug': '#00df5e',
  'Car Charging': '#ebb33b',
  'Heat Pump': '#a0be43',
  Switches: '#a8c9eb',
  'External Platform': '#e82828'
};

export const SPECIAL_STATES = {
  charging: {
    color: {
      pattern: {
        path: APP_ID === BKW_APP_ID ? '' : 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
        backgroundColor: 'white',
        width: 10,
        height: 10,
        color: 'red'
      }
    }
  },
  discharging: {
    color: {
      pattern: {
        path: APP_ID === BKW_APP_ID ? '' : 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
        backgroundColor: 'white',
        width: 10,
        height: 10,
        color: 'green'
      }
    }
  }
};

export const DEVICE_DEFAULT_COLOR = '#ffffff';
