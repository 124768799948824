import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Switcher } from '../../../../components/ReduxFormFields';
import { required, validateFloatWithThreeDecimal, parseFloatNumbersWithOneDigitAfterPoint } from '../../../validation';

import i18n from '../../../../i18n';
import { StepsEditFormBySeason } from './StepsEditFormBySeason';
import { Expander, Tooltip } from '../../../../components';
import { TariffTextField } from './TariffTextField';

export const DoubleTariff = ({ formValues = {}, unit = '', skipValidation = false }) => (
  <>
    <Typography
      sx={{ my: 2, textTransform: 'capitalize', color: 'secondary.dark' }}
      variant="body2"
    >
      {i18n.t('price')}
      {`: (${unit})`}
    </Typography>
    <Box sx={{ display: 'flex', columnGap: '35px', mb: 1 }}>
      <TariffTextField
        name="doubleTariff.prices.lowTariff"
        label={i18n.t('lowTariff')}
        variant="body2"
        labelSx={{ color: 'secondary.lowTariff' }}
        parser={parseFloatNumbersWithOneDigitAfterPoint}
        validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}
      />
      <TariffTextField
        name="doubleTariff.prices.highTariff"
        label={i18n.t('hightTariff')}
        variant="body2"
        labelSx={{ color: 'secondary.highTariff' }}
        parser={parseFloatNumbersWithOneDigitAfterPoint}
        validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}
      />
    </Box>
    <StepsEditFormBySeason formValues={formValues} field="commonSeason" />
    <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center', my: 3 }}>
      <Field
        name="doubleTariff.isWinterTimeEnabled"
        component={Switcher}
        props={{
          margin: 0,
          width: 50,
          height: 25,
          onColor: '#e1cd00',
          className: 'switcher m-0'
        }}
      />
      <Typography sx={{ color: 'secondary.main' }} variant="h4">
        {`${i18n.t('winterTimeTariff')}`}
        {' '}
        <Tooltip title={i18n.t('winterTimeTariffTooltip')} placement="right" />
      </Typography>
    </Box>
    <Expander expanded={Boolean(formValues.isWinterTimeEnabled)}>
      <Typography sx={{ color: 'secondary.dark', mt: 2, mb: 1 }} variant="body1">
        {`${i18n.t('wintertimeTariffDescription')}`}
      </Typography>
      <StepsEditFormBySeason formValues={formValues} field="winterSeason" />
    </Expander>
  </>
);

DoubleTariff.propTypes = {
  formValues: PropTypes.instanceOf(Object),
  unit: PropTypes.string,
  skipValidation: PropTypes.bool
};
