import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AddCirclePlus } from '../../../../../components/UIKit';
import { INVITE_SUB_USER_MODAL_ID, openModalWindow, REMOVE_SUB_USER_MODAL_ID } from '../../../../ModalWindow/slice';
import { getSmSubUsersSelector } from '../../../../../redux-store/selectors/dataList';
import { READ_ONLY_ROLES } from '../../../../../constants';

import i18n from '../../../../../i18n';
import '../index.scss';
import { transformResponse } from '../../../../../utils/transformResponse';
import { getSubUserData } from '../../../../DataList/slice';
import { dataListID } from '../../../../SubUsers/constants';
import { USERS_SUB_USER_SUB_METER_URL } from '../../../../../api/apiUrls';

/**
 * SubUsers headers
 *  @type {*}
 */
const headers = [
  {
    title: 'name'
  },
  {
    title: 'email'
  },
  {
    title: 'status'
  }
];

const SubUsers = (props) => {
  const { rowData, myRoleType, isReadOnlyPermission, defaultParams } = props;
  const dispatch = useDispatch();

  const currentSubMeterId = rowData?.original?._id;
  useEffect(() => {
    dispatch(getSubUserData(
      {
        listID: dataListID,
        dataList: {
          listURL: `${USERS_SUB_USER_SUB_METER_URL}/${currentSubMeterId}`,
          params: defaultParams,
          transformResponse,
          parentIdOfSubUsers: currentSubMeterId
        }
      }
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);
  const subUsers = useSelector(getSmSubUsersSelector);
  const subUsersArray = subUsers?.[currentSubMeterId]?.data;

  const handleRemove = (id) => dispatch(openModalWindow({ modalID: REMOVE_SUB_USER_MODAL_ID, data: { id, currentSubMeterId } }));
  const handleInvite = (id) => dispatch(openModalWindow({ modalID: INVITE_SUB_USER_MODAL_ID, data: { id } }));

  return (
    <div className="wrapper">
      <div className="sub-component-title">{i18n.t('subUsers')}</div>
      <div className="sub-component-container">
        <table>
          {subUsersArray?.length ? (
            <>
              <thead>
                <tr>
                  {headers.map(({ title }) => <th key={`${uuidv4()}-thead`}>{i18n.t(title)}</th>)}
                </tr>
              </thead>
              <tbody>
                {subUsersArray.map(({ first_name, last_name, email, status, _id }) => (
                  <tr key={`${uuidv4()}-tbody`}>
                    <td>{`${first_name} ${last_name}`}</td>
                    <td>{email}</td>
                    <td className={`${status === 'pending user confirmation' ? 'deactivated-td' : ''}`}>{i18n.t(status)}</td>
                    {!(isReadOnlyRole || isReadOnlyPermission) ? (
                      <td className="sub-component-delete">
                        <a
                          title={i18n.t('delete')}
                          onClick={handleRemove.bind(null, _id)}
                          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
                        >
                          <i className="la la-close" />
                        </a>
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <thead className="no-data-yet">
              <tr>
                <th>
                  {i18n.t('noSubUsersYet')}
                </th>
              </tr>
            </thead>
          )}
        </table>
        {!(isReadOnlyRole || isReadOnlyPermission) && (
          <div className="sub-component-add-icon">
            <AddCirclePlus
              width={38}
              handleClick={handleInvite.bind(null, currentSubMeterId)}
              title={i18n.t('addNewSubUser')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SubUsers.propTypes = {
  myRoleType: PropTypes.string,
  rowData: PropTypes.instanceOf(Object).isRequired,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default SubUsers;
