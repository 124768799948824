import { isDeutsch } from './isDeutsch';

const isFloat = (num) => (isDeutsch() ? /^[+-]?[0-9]+\,[0-9]+$/ : /^[+-]?[0-9]+\.[0-9]+$/).test(num);
const formatFloat = (num) => Number.parseFloat(isDeutsch() ? num.replace(',', '.') : num);

const formatFloatSeparator = (data) => {
  if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
    const formattedData = {};
    Object.keys(data).forEach((key) => {
      formattedData[key] = formatFloatSeparator(data[key]);
    });
    return formattedData;
  }

  if (isFloat(data)) {
    return formatFloat(data);
  }

  return data;
};

export default formatFloatSeparator;
