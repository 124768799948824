import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderAccess
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';

const columnHelper = createColumnHelper();

const columnsEmp = (myRoleType) => [
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor(
    (row) => `${row.first_name} ${row.last_name} ${row.avatar}`,
    {
      id: 'last_name',
      header: i18n.t('fName'),
      size: 180,
      cell: ({ row }) => RenderFullName(row)
    }
  ),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    cell: (d) => RenderEmail({ value: d.getValue() }),
    size: 225,
    meta: {
      tdClasses: 'email-text-td'
    }
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: 160,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: 169,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: 100,
    cell: ({ row }) => RenderAccess(row),
    enableSorting: false
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: 107,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    }
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: 120,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(d.row.original, {
      editUser: { preLink: '/setting-profile/employee/' },
      remove:
            myRoleType === 'root' || myRoleType === 'solar_admin' || myRoleType === 'pv_installer'
    })
  })
].filter(Boolean);

export default columnsEmp;
