import { createApi } from '@reduxjs/toolkit/query/react';
import { THERMONDO_SAML_AUTH_REQUEST_DATA_URL } from '../../../api/apiUrls';
import { api } from '../../../api';

// eslint-disable-next-line space-before-function-paren
const axiosBaseQuery = async ({ url, method, data, params }) => {
  try {
    const result = await api({ url, method, data, params });
    return { data: result };
  } catch (axiosError) {
    const { response = {} } = axiosError || {};

    return { error: { status: axiosError.code, message: response.data?.message || axiosError.message } };
  }
};

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['sso'],
  endpoints: (builder) => ({
    thermondoSamlAuthRequestData: builder.query({
      refetchOnMountOrArgChange: false,
      invalidatesTags: ['sso'],
      query: () => ({ url: THERMONDO_SAML_AUTH_REQUEST_DATA_URL, method: 'get' }),

      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { url, SAMLRequest } = data;

          const urlWithParams = new URL(url);
          urlWithParams.searchParams.set('SAMLRequest', SAMLRequest);

          window.location.href = urlWithParams;
        } catch (error) {
          console.error('Error in thermondoSamlAuthRequestData query', error);
        }
      }
    }),
    abbSSOLogin: builder.query({
      query: (role) => ({ url: `auth/${role}/oauth2/authUrl`, method: 'get' }),

      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          window.location.href = data.url;
        } catch (error) {
          console.error('Error in abbSSOLogin query', error);
        }
      }
    })
  })
});

export const {
  useLazyThermondoSamlAuthRequestDataQuery,
  useLazyAbbSSOLoginQuery
} = loginApi;
