import React from 'react';
import { useDispatch } from 'react-redux';

import { changePriority } from '../../../containers/UserDetails/slice';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column of priority for devices with change priority buttons.
 * @memberof module:ReactTable
 * @param  {object} data - properties of users
 */
const RenderPriority = (data) => {
  const { id, priority, userId, type, total } = data.getValue() || {};
  const { isActive } = data?.column?.columnDef || {};
  const dispatch = useDispatch();

  const url = `sensors/${type}/${userId}/${id}`;

  const handleClickUp = () => (isActive ? dispatch(changePriority({ url: `/../${url}/up` })) : undefined);
  const handleClickDown = () => (isActive ? dispatch(changePriority({ url: `/../${url}/down` })) : undefined);

  return (
    <>
      {priority}
      {type === 'devices' ? (
        <div className="dev-nav-arrow">
          {priority !== 1 ? (
            <a
              title={i18n.t('moveUpTooltip')}
              onClick={handleClickUp}
              className={`btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill dev-nav-prior${isActive ? '' : ' disabled'}`}
            >
              <i className="fa fa-angle-up" />
            </a>
          ) : null}
          {total !== priority ? (
            <a
              title={i18n.t('moveDownTooltip')}
              onClick={handleClickDown}
              className={`btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill dev-nav-prior${isActive ? '' : ' disabled'}`}
            >
              <i className="fa fa-angle-down" />
            </a>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default RenderPriority;
