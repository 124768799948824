import React, { useEffect, Fragment } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { getOrderIcon } from './components';
import i18n from '../../i18n';

export const ReactTableUI = ({
  columns = [],
  dataList = {},
  settings = {},
  sortField = '',
  sortDirection = 'asc',
  handleSort = () => {},
  SubComponent = undefined,
  tableRef = null,
  loading = false,
  columnVisibility = {},
  setRowSelection,
  rowSelection,
  isHideThead,
  tableStyles = {},
  getTrProps = () => {}
}) => {
  const table = useReactTable({
    data: (dataList.data ? dataList.data : dataList) || [],
    columns,
    onRowSelectionChange: setRowSelection,
    getRowCanExpand: () => !!SubComponent,
    defaultColumn: { minSize: 25, maxSize: 800 },
    onSortingChange: handleSort,
    manualSorting: true,
    manualPagination: true,
    enableRowSelection: true,
    state: {
      sorting: [{ id: sortField, desc: sortDirection === 'desc' }],
      columnVisibility: columnVisibility || settings?.columns || {},
      rowSelection
    },
    getRowId: (row) => row._id,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table;
    }
  }, [tableRef, table]);

  return (
    <div
      className="block react-table-v2"
      style={tableStyles}
    >
      {loading && (
        <div className="table-loader">
          {i18n.t('load')}
          ...
        </div>
      )}

      <div className="table">
        {isHideThead ? null : (
          <div
            style={{ minWidth: `max(${table.getTotalSize()}px, 100%)` }}
            className="thead"
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id} className="tr">
                {headerGroup.headers.map((header) => (
                  <div
                    className={`th ${header.column.columnDef.meta?.thClasses ?? ''}`}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      position: 'relative',
                      width: header.getSize(),
                      flex: header.column.getCanResize() ? '1 0 auto' : 'unset'
                    }}
                  >
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        position: 'relative',
                        ...(header.column.getCanSort() ? {
                          cursor: 'pointer',
                          userSelect: 'none'
                        } : {})
                      }}
                      className="react-table-header-cell "
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      {header.column.getCanSort() && getOrderIcon(header.column.getIsSorted(), sortDirection)}
                    </div>

                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        <div
          className="tbody"
          style={{ minWidth: `max(${table.getTotalSize()}px, 100%)` }}
        >
          {table.getRowModel().rows.length === 0 && (
            <div className="tr">
              <div
                className="td"
                style={{
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <div className="react-table-cell">
                  {i18n.t('noRowsFound')}
                </div>
              </div>
            </div>
          )}
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <div className={`tr ${rowSelection?.[row?.id] ? 'selected' : ''}`} {...getTrProps(row.original)}>
                {/* first row is a normal row */}
                {row.getVisibleCells().map((cell) => (
                  <div
                    className={`td ${cell.column.columnDef.meta?.tdClasses ?? ''}`}
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                      flex: cell.column.getCanResize() ? '1 0 auto' : 'unset',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >

                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}

                  </div>
                ))}
              </div>
              {row.getIsExpanded() && (
                <SubComponent original={row.original} />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

ReactTableUI.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  dataList: PropTypes.instanceOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object),
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  handleSort: PropTypes.func,
  SubComponent: PropTypes.func,
  tableRef: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  columnVisibility: PropTypes.instanceOf(Object),
  setRowSelection: PropTypes.func,
  rowSelection: PropTypes.instanceOf(Object),
  isHideThead: PropTypes.bool.isRequired,
  tableStyles: PropTypes.instanceOf(Object),
  getTrProps: PropTypes.func
};
