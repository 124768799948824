import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderDevicesErrorDetails,
  RenderSensorData,
  RenderHeaderWithTooltip
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EditColumns } from '../../../components';

const columnHelper = createColumnHelper();

/**
 * Creates input-devices columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const inputDevices = (myRoleType, isReadOnly, width = {}, columns = {}) => {
  const isAllowEdit = [
    'root',
    'solar_admin',
    'oem',
    'property_management',
    'pv_installer',
    'oem_employee',
    'pv_installer_employee_read_install',
    'pv_installer_employee'
  ].includes(myRoleType) && !isReadOnly;

  return [
    columnHelper.display({
      enableResizing: false,
      id: 'empty',
      header: '',
      size: 35
    }),
    columnHelper.accessor('device_group', {
      id: 'device_group',
      header: i18n.t('name'),
      size: width.device_group,
      cell: (d) => i18n.t(d.getValue()),
      enableSorting: false
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: i18n.t('description'),
      size: width.description,
      enableSorting: false
    }),
    columns.ip
      && columnHelper.accessor('ip', {
        id: 'ip',
        header: i18n.t('IP'),
        size: width.ip,
        enableSorting: false
      }),
    columns.mac
      && columnHelper.accessor('mac', {
        id: 'mac',
        header: i18n.t('MAC'),
        size: width.mac,
        enableSorting: false
      }),
    columns.signal
      && columnHelper.accessor('signal', {
        id: 'signal',
        header: i18n.t('signal'),
        size: width.signal,
        cell: (d) => RenderSignal({ value: d.getValue() }),
        enableSorting: false
      }),
    columns.errorcodes
      && columnHelper.accessor('errorcodes', {
        id: 'errorcodes',
        header: RenderHeaderWithTooltip({
          headerText: i18n.t('error'),
          tooltipText: i18n.t('errorsHintTooltip'),
          placement: 'right-start',
          style: {
            verticalAlign: 'middle',
            marginLeft: '6px'
          }
        }),
        size: width.errorcodes,
        cell: ({ row }) => RenderDevicesError(row),
        enableSorting: false
      }),
    !['end_user'].includes(myRoleType)
      && columns.errorDetails
      && columnHelper.accessor('errorDetails', {
        id: 'errorDetails',
        header: RenderHeaderWithTooltip({
          headerText: i18n.t('errorDetails'),
          tooltipText: i18n.t('errorsHintTooltip'),
          placement: 'right-start',
          style: {
            verticalAlign: 'middle',
            marginLeft: '6px'
          }
        }),
        size: width.errorDetails,
        cell: ({ row }) => RenderDevicesErrorDetails(row),
        enableSorting: false
      }),
    columnHelper.accessor('sensors_data', {
      id: 'sensors_data',
      header: i18n.t('data'),
      size: 60,
      cell: ({ row }) => RenderSensorData(row),
      enableResizing: false,
      enableSorting: false
    }),
    columnHelper.display({
      id: 'buttons',
      header: EditColumns,
      enableResizing: false,
      size: !isReadOnly ? width.buttons : undefined,
      meta: {
        thClasses: 'd-flex justify-content-end',
        tdClasses: 'justify-content-end action-buttons'
      },
      cell: (d) => RenderButtons(d.row.original, {
        editRow: isAllowEdit,
        removeDevice: isAllowEdit
      })
    })
  ].filter(Boolean);
};

export default inputDevices;
