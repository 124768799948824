import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput, IMask } from 'react-imask';
import Input from '@mui/material/Input';

import { CustomTimePicker } from '../../../TimePicker/CustomTimePicker';
import Tooltip from '../../../../Tooltip';
import i18n from '../../../../../i18n';

import './index.scss';

const defaultTime = '00:00';

const TextMaskCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="HH:MM"
      blocks={{
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
          maxLength: 2,
          autofix: true,
          placeholderChar: '00'
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          maxLength: 2,
          autofix: true,
          placeholderChar: '00'
        }
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export const TimePickerInput = (props) => {
  const {
    input,
    label,
    className,
    errorPosition = 'absolute',
    meta: { touched, error },
    shrink = true,
    placeholder,
    showTooltip = '',
    forceError = '',
    languageToSync,
    defaultValue
  } = props;

  const {
    value = defaultValue || defaultTime,
    onChange
  } = input;
  const [showPicker, setShowPicker] = useState(false);

  const handleChange = (event) => onChange(event.target.value);

  const errorMessage = forceError || error;

  return (
    <div className="switching-times-time-picker">
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="left" />
        </div>
      )}
      {label ? <div className="switching-times-label">{i18n.exists(label) ? i18n.t(label) : label}</div> : null}
      <Input
        onFocus={() => setShowPicker(true)}
        onBlur={() => setShowPicker(false)}
        label={i18n.exists(label) ? i18n.t(label) : label}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        classes={{
          root: 'inputRoot',
          input: 'inputField'
        }}
        className={`customTextField ${className}`}
        name="textmask"
        id={label}
        inputComponent={TextMaskCustom}
      />
      {showPicker && (
        <CustomTimePicker
          onChange={onChange}
          time={value}
        />
      )}
      <div className="time-picker-warning-container">
        {touched && (errorMessage || languageToSync) && (
          <div
            className="inputCustomWarning"
            style={{
              position: errorMessage ? errorPosition : 'absolute',
              zIndex: shrink ? 'undefined' : 99999
            }}
          >
            {i18n.t(errorMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

TimePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  languageToSync: PropTypes.string,
  errorPosition: PropTypes.string,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  shrink: PropTypes.bool,
  forceError: PropTypes.string,
  defaultValue: PropTypes.string
};
