import i18n from '../../i18n';

export const oneMinuteInMS = 60000;
export const fiveMinutesInMS = 300000;
export const dayInMS = 86400000;

export const weekInMS = 604800000;
export const hourInMS = 3600000;

export const chartGridColor = '#353535';
export const ticksColor = '#a7a8b2';
const noDataLabelColor = 'rgba(0, 0, 0, .5)';
export const houseFuseLineColor = '#3998F9';
export const houseFuseLabelColor = '#3998F9';
export const phase1Color = '#FF9E22';
export const phase2Color = '#00F6E6';
export const phase3Color = '#99BD68';

export const generalChartOptions = {
  accessibility: { enabled: false },
  credits: { enabled: false },
  legend: { enabled: false },
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  chart: {
    backgroundColor: 'transparent',
    style: { fontFamily: 'Poppins' }
  },

  title: { text: '' },
  noData: {
    style: {
      zIndex: 10,
      fontWeight: 'normal',
      fontSize: '20px',
      color: noDataLabelColor
    }
  },
  tooltip: {
    useHTML: true,
    shared: true,
    split: false,
    borderRadius: 15,
    backgroundColor: '#f2f2f2',
    borderColor: '#f2f2f2',
    shape: 'rectangle',
    valueSuffix: 'A',
    padding: 15,
    style: {
      color: '#000',
      fontSize: '16px'
    }
  }
};

export const getPlotLines = (houseFuse, isShowLabel) => [
  {
    dashStyle: '4px 4px',
    name: i18n.t('phase1'),
    value: [houseFuse],
    color: houseFuseLineColor,
    width: 3,
    zIndex: 1,
    label: {
      useHTML: true,
      align: 'right',
      text: isShowLabel ? `${i18n.t('houseFuseLabel')} ${houseFuse}A` : '',
      y: -15,
      x: -20,
      style: {
        color: houseFuseLabelColor,
        fontSize: '14px',
        fontWeight: 500,
        padding: '3px 10px',
        backgroundColor: '#fbfbfb',
        borderRadius: '15px',
        display: isShowLabel ? 'block' : 'none'
      }
    }
  },
  {
    dashStyle: '4px 4px',
    name: i18n.t('phase1'),
    value: [-houseFuse],
    color: houseFuseLineColor,
    width: 3,
    zIndex: 1,
    label: {
      useHTML: true,
      align: 'right',
      text: isShowLabel ? `${i18n.t('houseFuseLabel')} ${-houseFuse}A` : '',
      y: -15,
      x: -20,
      style: {
        color: houseFuseLabelColor,
        fontSize: '14px',
        fontWeight: 500,
        padding: '3px 10px',
        backgroundColor: '#fbfbfb',
        borderRadius: '15px',
        display: isShowLabel ? 'block' : 'none'
      }
    }
  }
];

export const getPlotBands = (houseFuse, arr) => {
  const isShowPlotBands = Math.max(...arr) >= houseFuse || Math.min(...arr) <= -houseFuse;
  return isShowPlotBands ? [
    {
      // from end chart to -houseFuse
      from: -100000,
      to: -houseFuse,
      color: 'rgba(255, 95, 89, 0.1)'
    },
    {
      // from -houseFuse to houseFuse
      from: -houseFuse,
      to: houseFuse,
      color: 'rgba(0, 151, 95, 0.1)'
    },
    {
      // from houseFuse to end chart
      from: houseFuse,
      to: 100000,
      color: 'rgba(255, 95, 89, 0.1)'
    }
  ] : undefined;
};
