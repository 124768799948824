import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { goBack } from 'react-router-redux';

import { logOut } from '../../containers/SignIn/slice';
import { CLOUD_STATUS_MODAL_ID, openModalWindow } from '../../containers/ModalWindow/slice';
import { setPagesSetting } from '../../containers/PagesSettings/slice';
import { requestCloudStatusMessage } from '../../containers/CloudStatusWindow/slice';

import { logoSelector } from './selectors';
import { getSignInUserSelector } from '../../redux-store/selectors/signIn';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { getCloudStatusMessagesSelector } from '../../redux-store/selectors/cloudStatus';

import Logo from '../Logo';
import Avatar from '../Avatar';
import { MainTabs, RenderUserBar } from './components';
import { SimplePopover } from '../UIKit';
import CompanyLogo from '../CompanyLogo';
import CloudStatusWindow from '../../containers/CloudStatusWindow';

import { useHorizontalScroll } from '../../hooks';
import { EMPLOYEE_ROLE_TYPES } from '../../containers/UserDetails/constants';

import i18n from '../../i18n';

import './styles.scss';
import '../../App.css';

/**
 * @module TopBar
 */
/**
 * Renders main TopBar
 * @memberof module:TopBar
 */
const TopBar = (props) => {
  const { showNavigation, goBackButton, visibleWeb, pageInfo, logo: logoFromProps } = props;
  const dispatch = useDispatch();

  const logoFromState = useSelector(logoSelector);
  const logo = logoFromProps || logoFromState;
  const user = useSelector(getSignInUserSelector);
  const pagesSettings = useSelector(getPagesSettingsSelector);
  const cloudStatusInfo = useSelector(getCloudStatusMessagesSelector);

  const { first_name, last_name, avatar, email, role } = user || {};

  const rolesWithCustomLogo = ['pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES, 'oem_employee', 'oem_employee_read_only'];
  const isAdmin = ['root', 'solar_admin'].includes(role.type);

  const scrollRef = useHorizontalScroll();
  const isNewStatus = cloudStatusInfo?.createdAt && pagesSettings.cloudStatusMessage.createdAt !== cloudStatusInfo.createdAt && !cloudStatusInfo.isDefaultMessage;

  const logOutHandler = () => {
    dispatch(logOut());
  };

  const openCloudStatusModal = () => dispatch(openModalWindow({ modalID: CLOUD_STATUS_MODAL_ID, data: cloudStatusInfo }));

  const RenderUserBarComponent = useCallback((innerProps) => <RenderUserBar innerProps={innerProps} isNewStatus={isNewStatus} />, [isNewStatus]);

  useEffect(() => {
    if (isNewStatus && !isAdmin && !cloudStatusInfo?.isDefaultMessage) {
      openCloudStatusModal();
      dispatch(setPagesSetting({ page: 'cloudStatusMessage', data: { createdAt: cloudStatusInfo.createdAt } }));
    }
  }, [isNewStatus, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAdmin) {
      dispatch(requestCloudStatusMessage({ roleType: role.type, language: i18n.language }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user?.role?.type) return null;

  return (
    <div className="topbar-dashb">
      {!isAdmin ? <CloudStatusWindow /> : null}
      <div ref={scrollRef} className="container-fluid topbar-inner-container">
        <div className="row align-items-start justify-content-start topbar-inner-container-row">
          <div className="col-12 mr-auto">
            <div className="row justify-content-between align-items-center" style={{ flexWrap: 'nowrap' }}>

              {(logo && visibleWeb) ? (
                <CompanyLogo logo={logo?.url} isTopBar />
              ) : null}

              {(!rolesWithCustomLogo.includes(role.type) || (!logo || !visibleWeb)) && (
                <div className="col-auto">
                  <Logo />
                </div>
              )}

              {showNavigation && (
                <div className="col-auto mr-auto">
                  <MainTabs />
                </div>
              )}

              {!goBackButton && (
                <div className="col-auto" style={{ float: 'right' }}>
                  <div
                    className="col-auto user-name text-right"
                    style={{ position: 'relative' }}
                  >
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <SimplePopover
                        className="dropdown-menu dropdown-menu-right show"
                        ButtonComponent={RenderUserBarComponent}
                      >
                        <div
                          className="m-dropdown__wrapper"
                          style={{ zIndex: 101, borderRadius: '10px' }}
                        >
                          <span
                            className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                            style={{ left: 'auto', right: '12.5px' }}
                          />
                          <div className="m-dropdown__inner">
                            <div
                              className="m-dropdown__header m--align-center"
                            >
                              <div className="m-card-user m-card-user--skin-dark">
                                <Avatar
                                  name={`${first_name} ${last_name}`}
                                  avatar={avatar ? avatar.url : false}
                                  className="medium"
                                />
                                <div className="m-card-user__details">
                                  <span className="m-card-user__name">
                                    {`${first_name} ${last_name}`}
                                  </span>
                                  <a
                                    href={`mailto:${email}`}
                                    className="m-card-user__email m--font-weight-300 m-link"
                                  >
                                    {email}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="m-dropdown__body">
                              <div className="m-dropdown__content">
                                <ul className="m-nav m-nav--skin-light">
                                  <li className="m-nav__item">
                                    <Link
                                      to="/setting-profile/"
                                      className="m-nav__link"
                                    >
                                      <i className="m-nav__link-icon flaticon-user-ok" />
                                      <span className="m-nav__link-title">
                                        <span className="m-nav__link-wrap">
                                          <span className="m-nav__link-text">
                                            {i18n.t('editProfile')}
                                          </span>
                                        </span>
                                      </span>
                                    </Link>
                                  </li>
                                  <li className="m-nav__separator m-nav__separator--fit" />
                                  <li className="m-nav__item">
                                    <button
                                      type="button"
                                      onClick={logOutHandler}
                                      className="btn m-btn--pill btn-metal m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                    >
                                      {i18n.t('logout')}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SimplePopover>
                    </span>
                  </div>
                </div>
              )}

              {(pageInfo || goBackButton) ? (
                <div className="col-auto">
                  <div className="row">

                    {/* User name + surname */}
                    {(pageInfo) ? <div className="col-auto dashb-page-info">{pageInfo}</div> : null}

                    {/* Button go back */}
                    {goBackButton ? (
                      <div className="col-auto">
                        <div className="btn-upd-dashb">
                          <button type="button" onClick={() => dispatch(goBack())}>
                            {i18n.t('back')}
                          </button>
                        </div>
                      </div>
                    ) : null}

                  </div>
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  goBackButton: PropTypes.bool,
  showNavigation: PropTypes.bool,
  pageInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  visibleWeb: PropTypes.bool,
  logo: PropTypes.instanceOf(Object)
};

export default TopBar;
