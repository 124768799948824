import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import 'moment/min/locales';
import translationEN from './assets/locales/english';
import translationDE_CH from './assets/locales/deutsch_CH';
import translationDE_DE from './assets/locales/deutsch_DE';
import translationFR from './assets/locales/french';
import translationIT from './assets/locales/italian';
import translationDK from './assets/locales/danish';

export const IETFLangs = {
  english: 'en-US',
  deutsch_ch: 'de-CH',
  deutsch_de: 'de-DE',
  french: 'fr-FR',
  italian: 'it-IT',
  danish: 'da-DK'
};

const resources = {
  english: {
    translation: translationEN
  },
  deutsch_ch: {
    translation: translationDE_CH
  },
  deutsch_de: {
    translation: translationDE_DE
  },
  french: {
    translation: translationFR
  },
  italian: {
    translation: translationIT
  },
  danish: {
    translation: translationDK
  }
};

const nodeEnv = process.env.NODE_ENV;

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'english',
    debug: nodeEnv !== 'production',
    resources,
    interpolation: {
      escapeValue: true
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      queryStringParams: { hash: uuidv4() }
    },
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed'
    }
  });

export function getLangCode(lang) {
  if ((typeof lang !== 'string' || lang === '') || (lang === 'Deutsch' || lang === 'Deutsch (Schweiz)')) {
    return 'deutsch_ch';
  }

  if (lang === 'Deutsch (Deutschland)') {
    return 'deutsch_de';
  }

  return lang.toLowerCase();
}

export const changeLanguageTo = (language, saveToStorage) => {
  const language_ = getLangCode(language);
  const langShort = language_.substring(0, 2);
  if (i18n.language !== language_) i18n.changeLanguage(language_);
  if (moment.locale !== langShort) moment.locale(langShort);
  if (saveToStorage) localStorage.setItem('language', language_);
  console.info(`Language changed to ${language_} ${langShort}`);
};

export const getLanguageFromStore = () => (
  localStorage.getItem('language')
);

export const transformLanguageForAPI = (language) => {
  if (language === 'deutsch_ch') {
    return 'Deutsch';
  }

  if (language === 'deutsch_de') {
    return 'Deutsch (Deutschland)';
  }

  if (language?.length > 1) {
    return `${language[0].toUpperCase()}${language.substr(1)}`;
  }
  return undefined;
};

export const getShortLanguageName = (fullLanguageName = '') => {
  if (fullLanguageName === 'Deutsch (Schweiz)' || fullLanguageName === 'Deutsch') {
    return 'de_ch';
  }

  if (fullLanguageName === 'Deutsch (Deutschland)') {
    return 'de_de';
  }

  return fullLanguageName.slice(0, 2);
};

export const getFullLanguageName = (shortLanguageName = '') => {
  switch (shortLanguageName) {
    case 'en':
      return 'english';
    case 'de_ch':
      return 'deutsch_ch';
    case 'de_de':
      return 'deutsch_de';
    case 'fr':
      return 'french';
    case 'it':
      return 'italian';
    case 'da':
      return 'danish';
    default:
      return '';
  }
};

export default i18n;
