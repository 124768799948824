// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderPhoneNumber
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { width }) => ([
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor('first_name', {
    id: 'first_name',
    header: i18n.t('fName'),
    cell: (d) => RenderFullName(d.row),
    size: width.first_name
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    cell: (d) => RenderEmail({ value: d.getValue() }),
    size: width.email,
    meta: {
      tdClasses: 'email-text-td'
    }
  }),
  columnHelper.accessor('phone', {
    id: 'phone',
    header: i18n.t('phoneNumber'),
    cell: ({ row }) => RenderPhoneNumber(row),
    size: width.phone,
    meta: {
      tdClasses: 'phone_num-td'
    }
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: width.city,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    cell: (d) => RenderStatus({ value: d.getValue() }),
    size: width.status,
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    size: width.last_login
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: width.buttons,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(
      d.row.original,
      {
        view: { preLink: '/property-managements/' },
        editUser: { preLink: '/setting-profile/property-management/' },
        remove: ['root', 'solar_admin'].includes(myRoleType)
      }
    )
  })
]);

export default columns;
