export const DOMAINAPI = process.env.REACT_APP_DOMAINAPI || 'https://main-api.solar-manager.ch';
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://socket.solar-manager.ch';
export const HEALTH_CHECK_API = process.env.REACT_APP_HEALTH_CHECK_API || 'https://maintenance.solar-manager.ch';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@solarmanager.ch';

export const DEFAULT_TIMEZONE = 'UTC';
export const TERMS_AND_CONDITIONS_LINK = 'https://www.en-co.ch/impressum';
export const PRIVACY_POLICY_LINK = 'https://www.en-co.ch/datenschutz';

export const THREE_S_APP_ID = 'threeSEndUserApp';
export const HELION_APP_ID = 'helionEndUserApp';
export const RENNERGY_APP_ID = 'rennergyEndUserApp';
export const SOLAR_MANAGER_APP_ID = 'solarManagerEndUserApp';
export const ETAVIS_APP_ID = 'etavisEnergyManagerEndUserApp';
export const E_CONNECT_APP_ID = 'energyConnectEndUserApp';
export const BKW_APP_ID = 'homeEnergyConnectEndUserApp';
export const TOBLER_APP_ID = 'smartSolarEndUserApp';
export const ENVISION_APP_ID = 'envisionEndUserApp';
export const ASKOMA_APP_ID = 'askomaEndUserApp';
export const EKZ_APP_ID = 'EKZEndUserApp';
export const EZEE_APP_ID = 'ezeeEndUserApp';
export const CKW_APP_ID = 'ckwEndUserApp';
export const FEBESOL_APP_ID = 'febesolEndUserApp';
export const THERMONDO_APP_ID = 'thermondoEndUserApp';
export const ABB_APP_ID = 'abbEndUserApp';
export const SOLAREINS_APP_ID = 'solareinsEndUserApp';

export const APP_ID = E_CONNECT_APP_ID;
