/* eslint-disable react/prop-types */
import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import i18n from '../../i18n';
import { ReactComponent as FileIco } from '../../assets/images/svg/file.svg';
import { RenderButtons } from '../../components/ReactTable/renderFunctions';
import { transformBytesToNiceSize } from './helpers';
import { allowedRolesForUpdateAndDeleteFiles } from './constants';
import { getCheckbox } from '../../components/ReactTableV2';

const columnHelper = createColumnHelper();

export const getColumns = (myRoleType, isReadOnly) => [
  {
    id: 'select-col',
    enableResizing: false,
    header: ({ table }) => getCheckbox({
      checked: table.getIsAllRowsSelected(),
      indeterminate: table.getIsSomeRowsSelected(),
      onChange: table.getToggleAllRowsSelectedHandler()
    }),

    cell: ({ row }) => getCheckbox({
      checked: row.getIsSelected(),
      disabled: !row.getCanSelect(),
      indeterminate: row.getIsSomeSelected(),
      onChange: row.getToggleSelectedHandler()
    }),
    meta: {
      thClasses: 'select-col p-0',
      tdClasses: 'select-col p-0'
    }
  },
  columnHelper.accessor('fileName', {
    id: 'name',
    header: i18n.t('name'),
    size: 180,
    cell: (d) => (
      <div className="documentation-table-cell">
        <FileIco />
        <p>{d.getValue()}</p>
      </div>
    ),
    enableSorting: false
  }),
  columnHelper.accessor('fileSize', {
    id: 'size',
    header: i18n.t('size'),
    size: 180,
    cell: (d) => transformBytesToNiceSize(d.getValue()),
    enableSorting: false
  }),
  columnHelper.accessor('updatedAt', {
    id: 'lastModified',
    header: i18n.t('lastModified'),
    size: 180,
    cell: (d) => moment(d.getValue()).format('DD.MM.YYYY LT'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: 120,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(d.row.original, {
      downloadFile: true,
      deleteFile: allowedRolesForUpdateAndDeleteFiles.includes(myRoleType) && !isReadOnly
    })
  })
].filter(Boolean);
