import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListHocForDevicesTable } from '../../../DataList';
import columns from '../../columns';
import { SENSORS_LIST_ID } from '../../../UserDetails/constants';
import { getUserLicenseSelector } from '../../selectors';
import { DEVICE_LIMIT_REACHED_POPUP, openModalWindow } from '../../../ModalWindow/slice';
import { READ_ONLY_ROLES } from '../../../../constants';
import i18n from '../../../../i18n';

/**
/**
 * @module DevicesTable
 */
/**
 * Devices tab from DevicesTable
 * @memberof module:DevicesTable
 */
const DevicesTab = (props) => {
  const {
    userId,
    myRoleType,
    openAddDevices,
    defaultParams,
    transformResponse,
    devicesUrl,
    total,
    widthSetting,
    columnsSetting = {},
    isReadOnlyPermission
  } = props;
  const userLicense = useSelector(getUserLicenseSelector);
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);
  const dispatch = useDispatch();

  const openAddDeviceModal = () => {
    if (userLicense?.restrictions?.numberOfAllowedDevices && total >= userLicense?.restrictions?.numberOfAllowedDevices) {
      return dispatch(openModalWindow({ modalID: DEVICE_LIMIT_REACHED_POPUP }));
    }

    return openAddDevices();
  };

  return (
    <>
      {!(isReadOnlyRole || isReadOnlyPermission) && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddDeviceModal}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addDevicebtn')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataListHocForDevicesTable
          tab="devices"
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          columns={columns(myRoleType, userId, 'devices', total, isReadOnlyPermission, widthSetting, columnsSetting)}
        />
      </div>
    </>
  );
};

DevicesTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  devicesUrl: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  openAddDevices: PropTypes.func,
  transformResponse: PropTypes.func.isRequired,
  widthSetting: PropTypes.instanceOf(Object),
  columnsSetting: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default DevicesTab;
