import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { MultipleSelect, CustomSelect } from '../../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { affectedDevicesOptionsSelector, inputDevicesOptionsSelector } from './selectors';
import { getModalsIsLoadingSelector, getModalsSelector } from '../../../redux-store/selectors/modals';
import { required, requiredMultiple } from '../../validation';
import { EDIT_ROW_MODAL_ID, UPGRADE_TO_UNLOCK_POPUP, closeModalWindow, openModalWindow } from '../../ModalWindow/slice';

import { additionalFields } from '../utils';
import i18n from '../../../i18n';
import { getUserLicenseSelector } from '../selectors';

/**
 * EditDeviceWindow form id
 */
const formID = 'editLoadguardForm';

/**
 * Modal window for editing device
 * @memberof module:UserDevices
 */
const EditDeviceWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const modals = useSelector(getModalsSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const inputDevicesOptions = useSelector(inputDevicesOptionsSelector);
  const affectedDevicesOptions = useSelector(affectedDevicesOptionsSelector);
  const userLicense = useSelector(getUserLicenseSelector);
  const modal = modals?.[EDIT_ROW_MODAL_ID];

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: EDIT_ROW_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const onSubmit = () => {
    if (userLicense?.restrictions?.allowedDeviceTypes?.length) {
      if (!userLicense.restrictions.allowedDeviceTypes.includes('loadguards')) {
        return dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
      }
    }

    return handleSubmit();
  };

  return (
    <ModalWindow modalID={EDIT_ROW_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              id={formID}
              className="m-login__form m-form pop-up-form add-sm-us"
              onSubmit={handleSubmit}
            >
              <div className="form-group m-form__group input-field zIndex20">
                <div className="flags-select-label">{`${i18n.t('inputDevice')} *`}</div>
                <Field
                  name="inputDevice"
                  component={CustomSelect}
                  placeholder={i18n.t('selectInputDevice')}
                  options={inputDevicesOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  disabled
                />
              </div>
              <div className="form-group m-form__group input-field zIndex19">
                <Field
                  name="inputDeviceFunction"
                  {...additionalFields.inputDeviceFunction}

                />
              </div>
              <div className="form-group m-form__group flex-multiple-select">
                <Field
                  label={`${i18n.t('affectedDevices')} *`}
                  name="affectedDevices"
                  component={MultipleSelect}
                  placeholder={i18n.t('selectAffectedDevices')}
                  options={affectedDevicesOptions}
                  validate={[requiredMultiple]}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton disabled={pristine} type="save" onClickHandler={onSubmit} />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

EditDeviceWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: formID,
  enableReinitialize: true
})(EditDeviceWindow);

export default form;
