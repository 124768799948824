import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { setPagesSettingColumnsWidth } from '../PagesSettings/slice';
import { ReactTableV2 } from '../../components/ReactTableV2';

const getTrProps = (original = {}) => {
  if (original?.deviceActivity === 3) {
    return { style: { opacity: 0.5 } };
  }

  return {};
};

export const DataListHocForDevicesTable = (props) => {
  const { tab, ...rest } = props;

  const dispatch = useDispatch();

  const { userDetails } = useSelector(getPagesSettingsSelector);

  const savePageColumnWidth = (ref) => { // investigate this
    const transformedData = ref?.current?.reduce((acc, { id: sortId, value }) => ({ ...acc, [sortId]: value }), {});

    const { [tab]: currentDeviceWidthSetting } = userDetails.width;

    dispatch(setPagesSettingColumnsWidth({ page: 'userDetails', data: { [tab]: { ...currentDeviceWidthSetting, ...transformedData } } }));
  };

  return <ReactTableV2 savePageColumnWidth={savePageColumnWidth} {...rest} getTrProps={getTrProps} />;
};

DataListHocForDevicesTable.propTypes = {
  listID: PropTypes.string.isRequired,
  Component: PropTypes.func,
  componentProps: PropTypes.instanceOf(Object),
  listURL: PropTypes.string,
  tab: PropTypes.string.isRequired,
  transformResponse: PropTypes.func,
  params: PropTypes.instanceOf(Object)
};
