// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table'; import {
  RenderDate,
  RenderStatus,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';
import { getExpandedIcon } from '../../components/ReactTableV2';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { width }) => ([
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  columnHelper.accessor('oem', {
    id: 'oem',
    header: i18n.t('company'),
    cell: (d) => d.getValue()?.name || 'No company',
    size: width.oem,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: width.city,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    cell: (d) => RenderStatus({ value: d.getValue() }),
    size: width.status,
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    size: width.last_login
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: width.buttons,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => RenderButtons(
      d.row.original,
      {
        devices: false,
        editUser: { preLink: '/setting-profile/oem/' },
        view: { preLink: '/oem/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    )
  })
]);

export default columns;
