import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import i18n from '../../../i18n';
import { CHOOSE_LICENSE_MODAL_ID, openModalWindow } from '../../../containers/ModalWindow/slice';

import '../index.scss';

const RenderLicense = (props) => {
  const { userId, licenseId, licenseTitle, parentListId, hide } = props.getValue();
  const {
    original: { _id, initialLicense }
  } = props.row;
  const { name, licenseCode } = initialLicense || {};

  const dispatch = useDispatch();

  if (hide) return null;

  const onClickHandler = () => dispatch(
    openModalWindow({
      modalID: CHOOSE_LICENSE_MODAL_ID,
      data: {
        userId,
        license: licenseId,
        parentListId,
        gatewayId: _id,
        licenseCode
      }
    })
  );

  return (
    <>
      <a
        title={i18n.t('editTooltip')}
        onClick={onClickHandler}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        style={{
          margin: '-2px 2px 2px 0'
        }}
      >
        <i className="la la-pencil" />
      </a>
      <span>{licenseTitle || name}</span>
    </>
  );
};

RenderLicense.propTypes = {
  row: PropTypes.instanceOf(Object),
  original: PropTypes.instanceOf(Object).isRequired,
  getValue: PropTypes.func.isRequired
};

export default RenderLicense;
