/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useZxing } from 'react-zxing';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import { getModalsSelector } from '../../redux-store/selectors/modals';
import { CancelButton } from '../../components/UIKit';
import { QR_CODE_SCANNER_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';

import i18n from '../../i18n';

import './index.scss';

const QRCodeScannerModalWindow = (props) => {
  const { scanQR } = props;
  const dispatch = useDispatch();
  const modals = useSelector(getModalsSelector);
  const modal = modals?.[QR_CODE_SCANNER_MODAL_ID];
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));

  const {
    ref,
    torch: { on, off }
  } = useZxing({
    onDecodeResult(result) {
      if (result) {
        scanQR(result.getText());
        off();
      }
    },
    onError(err) {
      console.error(err);
      handleOnClose();
    },
    paused: !modal?.opened
  });

  useEffect(() => {
    if (modal?.opened) {
      on();
    } else {
      off();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow isModalOver modalID={QR_CODE_SCANNER_MODAL_ID}>
      <div className="modal-header">
        <CancelButton onClickHandler={handleOnClose} customButtonClass="QR-code-header-button" />
        <h5 className="modal-title">{i18n.t('QRLink')}</h5>
      </div>
      <div className="modal-body">
        <video ref={ref} width="100%" />
      </div>
    </ModalWindow>
  );
};

QRCodeScannerModalWindow.propTypes = {
  scanQR: PropTypes.func.isRequired
};

export default QRCodeScannerModalWindow;
