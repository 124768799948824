/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import { RenderButtons, RenderStatus, RenderResendEmail, RenderAccess } from '../../../components/ReactTable/renderFunctions';
import { DELETE_INVITED_USER_MODAL_ID } from '../constants';

import i18n from '../../../i18n';

export const getCrumbs = (userId, myRoleType) => (myRoleType === 'end_user' ? [
  { name: i18n.t('settings'), url: '/my-devices' },
  { name: i18n.t('invitedUsers') }
] : [
  { name: i18n.t('SMUs'), url: '/users' },
  { name: i18n.t('settings'), url: `/users/${userId}` },
  { name: i18n.t('invitedUsers') }
]);

const columnHelper = createColumnHelper();

export const getViewerColumns = (myRoleType, isReadOnly, sendEmailRequest) => [
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'full_name',
    header: i18n.t('fName'),
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    meta: {
      tdClasses: 'email-text-td'
    },
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: 100,
    enableSorting: false,
    cell: ({ row }) => RenderAccess(row)
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('status'),
    size: 169,
    enableSorting: false,
    meta: {
      tdClasses: 'acc_status-td'
    },
    cell: (d) => RenderStatus({ value: d.getValue() })
  }),
  !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) && !isReadOnly && columnHelper.display({
    id: 'emailBtn',
    header: '',
    size: 35,
    cell: ({ row }) => (<RenderResendEmail email={row.original.email} sendEmailRequest={sendEmailRequest} />),
    enableSorting: false,
    meta: {
      tdClasses: 'acc_status-td'
    }
  }),
  !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) && columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: 70,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => (
      <div>
        {RenderButtons(
          d.row.original,
          { deleteInvitedUser: !isReadOnly, modalID: DELETE_INVITED_USER_MODAL_ID, editUserAccess: !isReadOnly }
        )}
      </div>
    )
  })
].filter(Boolean);

export const getInstallerColumns = (myRoleType, isReadOnly) => [
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'full_name',
    header: i18n.t('fName'),
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor((row) => `${row.company} ${row.oem}`, {
    id: 'installerName',
    header: i18n.t('installerName'),
    size: 180,
    enableSorting: false,
    cell: ({ row }) => row.original?.company?.name || row.original?.oem?.name || ''
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    meta: {
      tdClasses: 'email-text-td'
    },
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: 100,
    enableSorting: false,
    cell: ({ row }) => RenderAccess(row)
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('status'),
    size: 169,
    enableSorting: false,
    meta: {
      tdClasses: 'acc_status-td'
    },
    cell: (d) => RenderStatus({ value: d.getValue() })
  }),
  !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) && columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: 70,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => (
      <div>
        {RenderButtons(
          d.row.original,
          { deleteInvitedUser: !(d.row.original?.original || isReadOnly), modalID: DELETE_INVITED_USER_MODAL_ID, editUserAccess: !(d.row.original?.original || isReadOnly) }
        )}
      </div>
    )
  })
].filter(Boolean);

export const getPropMgmtColumns = (myRoleType, isReadOnly) => [
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'full_name',
    header: i18n.t('fName'),
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    meta: {
      tdClasses: 'email-text-td'
    },
    size: 180,
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('status'),
    size: 169,
    enableSorting: false,
    meta: {
      tdClasses: 'acc_status-td'
    },
    cell: (d) => RenderStatus({ value: d.getValue() })
  }),
  !['viewer', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem', 'oem_employee', 'oem_employee_read_only', 'end_user'].includes(myRoleType) && columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: 70,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end action-buttons'
    },
    cell: (d) => (
      <div>
        {RenderButtons(
          d.row.original,
          { deleteInvitedUser: !isReadOnly, modalID: DELETE_INVITED_USER_MODAL_ID }
        )}
      </div>
    )
  })
].filter(Boolean);
