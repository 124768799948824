import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'react-router-redux';

import i18n, { getShortLanguageName, getFullLanguageName, changeLanguageTo } from '../../../i18n';

const useAddLanguageParam = () => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('l');

  useEffect(() => {
    if (token) {
      const currentFullLanguage = getFullLanguageName(token);
      if (currentFullLanguage === '') {
        queryParams.set('l', 'de');
        dispatch(replace(`${window.location.pathname}?${queryParams.toString()}`));
      } else if (currentFullLanguage !== i18n.language) {
        changeLanguageTo(currentFullLanguage, true);
      }
    } else {
      queryParams.set('l', getShortLanguageName(i18n.language));
      dispatch(replace(`${window.location.pathname}?${queryParams.toString()}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useAddLanguageParam;
